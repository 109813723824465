import { HasOwnProperty } from './DataValidation'

/**
 * Updates the properties of the 'right' object with the corresponding properties from the 'left' object.
 *
 * @param {T} left - The object containing the updated properties.
 * @param {U} right - The object to be updated.
 * @return {U} The updated 'right' object.
 */
export default function DiffUpdate<T, U>(left: T, right: U): U {
  for (const key in right) {
    if (HasOwnProperty(right, key) === false) {
      continue
    }

    if (typeof right[key.valueOf()] === 'object' && right[key] !== null) {
      DiffUpdate(left[key.valueOf()], right[key])
      continue
    }

    if (left[key.valueOf()] !== right[key]) {
      right[key] = left[key.valueOf()]
    }
  }
  return right
}
