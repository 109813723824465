import * as Icons from './icons'
import * as Themes from './themes'
import * as Typography from './typography'

export { Icons, Themes, Typography }

export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const WS_API_URL = process.env.NEXT_PUBLIC_WS_API_URL

export const WS_ANONYMOUS_SYNC_HUB_ENDPOINT = '/hubs/anonymous-sync'
export const WS_SECURE_SYNC_HUB_ENDPOINT = '/hubs/secure-sync'

export const REGISTER_WEB_ENDPOINT = '/signup'
export const AUTH_ENDPOINT = '/api/auth'
export const AUTH_REFRESH_TOKEN_ENDPOINT = '/api/auth/refresh-token'
export const AUTH_PROVIDER_ENDPOINT = '/api/auth/provider'
export const REGISTER_ENDPOINT = '/api/auth/register'
export const VALIDATE_INVITATION_ENDPOINT =
  '/api/auth/validate-invitation-token'
export const VALIDATE_RESET_TOKEN_ENDPOINT =
  '/api/auth/forgot-password/validate-reset-token'
export const FORGOT_PASSWORD_ENDPOINT = '/api/auth/forgot-password'

export const ACTIVITIES_STATISTICS_ENDPOINT = '/api/users/activities-statistics'
export const EARNS_ENDPOINT = '/api/users/earns'
export const CAPTURE_ENDPOINT = '/api/users/{userId}/capture'
export const CAPTURE_FILTERS_ENDPOINT = '/api/users/capture-filters'
export const BLOCKS_ENDPOINT = '/api/users/blocks'
export const OFFERS_STATUS_ENDPOINT = '/api/users/offers-status'
export const REJECT_OFFERS_ENDPOINT = '/api/users/reject-offers'

// Transactional Approval challenges
export const RESEND_OTP_ENDPOINT = '/api/users/resend-otp'
export const VERIFY_OTP_ENDPOINT = '/api/users/verify-otp'

export const PROFILE_SETTINGS_ENDPOINT = '/api/users/settings'
export const CHANGE_PASSWORD_ENDPOINT = '/api/users/change-password'
export const CHANGE_LANGUAGE_ENDPOINT = '/api/users/change-language'
export const LANGUAGES_ENDPOINT = '/api/users/languages'

export const SCHEDULES_ENDPOINT = '/api/users/schedules'
export const TOUR_ENDPOINT = '/api/users/tour-statuses'
export const SERVICE_AREAS_ENDPOINT = '/api/users/service-areas'
export const WEEK_DAYS_FILTER_ENDPOINT = '/api/users/week-days-filter'

export const GENERATE_INVITATION_ENDPOINT = '/api/users/generate-invitation'
export const INVITATIONS_ENDPOINT = '/api/users/invitations'

export const CLAIM_REWARD_ENDPOINT = '/api/users/claim-reward'
export const REWARDS_EARNED_ENDPOINT = '/api/users/rewards-earned'

// offers
export const CANCEL_AUTO_REJECT_OFFER_ENDPOINT =
  '/api/users/cancel-auto-reject-offer'

// Business Logic
export const DAYS_TO_INVITATION_EXP = 2
export const MAX_ELEMENT_TIME_RANGE_LIST = 5

// UI
export const AUTO_SUBMIT_DELAY = 2000
export const AUTO_CLOSE_NOTIFICATION_DELAY = 30_000
export const SHOW_TOUR_PROGRESSION_DELAY = 5000

export const TRIAL_PERIOD_CODE_REWARD = 'TRIAL_PERIOD'
export const TRIAL_PERIOD_TIME_DAYS_MSG = 1

export const AUTH_FAIL_NOTIFICATION_TITLE = 'auth.fail.notification.title'

// Auth
export const AUTH_RETRY_MAX = 2 // number of times to retry auth, index start = 0

// Activities and Statistics
export const UPDATE_STATISTICS_INTERVAL_MS = 10 * 1000
export const UPDATE_OFFERS_CAPTURED_INTERVAL_MS = 10 * 1000
