import { v4 as uuidv4 } from 'uuid'

export class Alert {
  readonly uuid: string
  readonly title: string
  readonly type: AlertTypes
  readonly text: string
  metadata?: string
  status: AlertStatus

  constructor(
    title: string,
    text: string,
    type: AlertTypes = AlertTypes.Info,
    metadata?: string
  ) {
    this.uuid = uuidv4()
    this.title = title
    this.type = type
    this.text = text
    this.metadata = metadata
    this.status = AlertStatus.Pending
  }
}

export enum AlertTypes {
  Error,
  Info
}

export enum AlertStatus {
  Pending,
  Delivered
}
