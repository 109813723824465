import { ClaimRewardRequest } from '@models/dtos/ClaimRewardRequest'
import { Reward } from '@models/Reward'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { claimReferrerRewardRequestService } from '@services/referred.service'
import { getRewardsRequestService } from '@services/rewards.service'
import { GetStorage } from '@shared/StorageHandler'

interface RewardsState {
  rewards: Reward[] | undefined
  isLoading: boolean
  isClaimed: boolean
  error: any | undefined
}

const initialState: RewardsState = {
  rewards: undefined,
  isLoading: false,
  isClaimed: false,
  error: undefined
}

export const getRewards = createAsyncThunk(
  'rewards/getRewards',
  async (_, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return getRewardsRequestService(session.token)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const claimRewardRequest = createAsyncThunk(
  'referred/claimReward',
  async (data: ClaimRewardRequest, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return claimReferrerRewardRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const rewards = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRewards.pending, (state) => {
        state.isLoading = true
        state.isClaimed = false
      })
      .addCase(getRewards.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.rewards = payload
      })
      .addCase(getRewards.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })

      // Claim Reward
      .addCase(claimRewardRequest.pending, (state) => {
        state.isLoading = true
        state.isClaimed = false
      })
      .addCase(claimRewardRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isClaimed = true
      })
      .addCase(claimRewardRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default rewards.reducer
