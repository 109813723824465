import { API_URL, LANGUAGES_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Language } from '@models/Settings'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig } from 'axios'

export const languagesRequestService = async () => {
  const resp = await SecureRequestMiddleware<ResponseBase<Language[]>>(
    async (config: AxiosRequestConfig) => {
      return await axios.get<ResponseBase<Language[]>>(
        String(API_URL) + String(LANGUAGES_ENDPOINT),
        config
      )
    }
  )

  if (resp.status !== 200) {
    return undefined
  }

  return resp.data.result
}
