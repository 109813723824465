import { API_URL, OFFERS_STATUS_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Offer } from '@models/Offer'
import axios, { AxiosRequestConfig } from 'axios'

export const offersRequestService = async () => {
  const resp = await SecureRequestMiddleware<Offer[]>(
    async (config: AxiosRequestConfig) => {
      return await axios.get<Offer[]>(
        String(API_URL) + String(OFFERS_STATUS_ENDPOINT),
        config
      )
    }
  )

  if (resp.status !== 200) return undefined

  return resp.data
}
