import { ACTIVITIES_STATISTICS_ENDPOINT, API_URL } from '@constants'
import { ActivitiesStatistics } from '@models/ActivitiesStatistics'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SecureRequestMiddleware } from '../lib/secure_request.middleware'

export async function activitiesStatisticsRequestService(
  data?: ActivitiesStatistics
): Promise<ActivitiesStatistics> {
  let resp: AxiosResponse<ActivitiesStatistics, unknown> | undefined

  if (data === undefined) {
    // GET
    resp = await SecureRequestMiddleware<ActivitiesStatistics>(
      async (config: AxiosRequestConfig) => {
        return await axios.get<ActivitiesStatistics>(
          String(API_URL) + String(ACTIVITIES_STATISTICS_ENDPOINT),
          config
        )
      }
    )
  } else {
    // POST
    resp = await SecureRequestMiddleware<ActivitiesStatistics>(
      async (config: AxiosRequestConfig, _data: ActivitiesStatistics) => {
        return await axios.post<ActivitiesStatistics>(
          String(API_URL) + String(ACTIVITIES_STATISTICS_ENDPOINT),
          _data,
          config
        )
      },
      data
    )
  }

  if (resp?.status === 200) {
    return resp.data
  }

  return undefined
}
