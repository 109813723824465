import { useForgotPassword } from '@hooks/useForgotPassword'
import React from 'react'
import SetNewPassword from './SetNewPassword'
import StepFinal from './StepFinal'
import StepOne from './StepOne'
import CheckEmail from './CheckEmail'
import { useRouter } from 'next/router'

export default function ForgotPassword() {
  const {
    activeStep,
    resetToken,
    isLoading,
    isSuccess,
    isReseted,
    resetPassword,
    saveNewPassword
  } = useForgotPassword(3)

  const router = useRouter()

  return (
    <div className="forgot-password container flex flex-col items-center mx-auto w-[328px] md:w-[440px]">
      {resetToken === undefined && (
        <>
          {isSuccess === true ? (
            <CheckEmail />
          ) : (
            <StepOne
              step={activeStep}
              isLoading={isLoading}
              action={resetPassword}
            />
          )}
        </>
      )}

      {resetToken !== undefined && (
        <>
          {isReseted === false ? (
            <SetNewPassword action={saveNewPassword} isLoading={isLoading} />
          ) : (
            <StepFinal
              action={() => {
                void router.push('/')
              }}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </div>
  )
}
