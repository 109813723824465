import { AUTH_RETRY_MAX } from '@constants/index'
import { Session } from '@models/Session'
import { RefreshTokenService } from '@services/auth.service'
import { GetStorage, SetStorage } from '@shared/StorageHandler'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export type MiddlewareCallback<T, R = AxiosResponse<T> | undefined> = (
  config: AxiosRequestConfig,
  data?: T
) => Promise<R>

export async function SecureRequestMiddleware<
  TData,
  R = AxiosResponse<TData> | undefined,
  TParam = undefined
>(
  cb: MiddlewareCallback<TData, R>,
  data: TData = undefined,
  params: TParam = undefined,
  retry = AUTH_RETRY_MAX
): Promise<R> {
  const session = GetStorage<Session>('session') as Session
  const config = getAxiosConfig<TData, TParam>(session, data, params)

  return await cb(config, data)
    .catch(async (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return await unauthorizerRequestHandler<TData, R, TParam>(
          session,
          cb,
          data,
          params,
          retry
        )
      }

      return error
    })
    .then(async (response) => {
      if (response?.status === 200) return response

      if (response?.status === 401 || response?.status === 403) {
        return await unauthorizerRequestHandler<TData, R, TParam>(
          session,
          cb,
          data,
          params,
          retry
        )
      }

      return undefined
    })
}

async function unauthorizerRequestHandler<
  T,
  R = AxiosResponse<T> | undefined,
  P = undefined
>(
  session: Session,
  cb: MiddlewareCallback<T, R>,
  data: T,
  params: P,
  retry: number
) {
  const refreshTokenResp = await RefreshTokenService(session)
  if (refreshTokenResp.status === 200) {
    saveSession(refreshTokenResp.data)
  }
  if (retry > 0) {
    retry -= 1
    return await SecureRequestMiddleware<T, R, P>(cb, data, params, retry)
  }

  return undefined
}

function saveSession(session: Session) {
  if (session?.token !== undefined) {
    SetStorage<Session>('session', session)
  }
}

function getAxiosConfig<TData, TParam>(
  session: Session,
  data?: TData,
  params?: TParam
): AxiosRequestConfig {
  const token: string = session.token

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
  if (data !== undefined) config.data = data
  if (params !== undefined) config.params = params

  return config
}
