import { API_URL, TOUR_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { TourStatus } from '@models/TourStatus'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const tourRequestService = async (data?: TourStatus[]) => {
  let resp: AxiosResponse<TourStatus[], unknown> | undefined

  if (data === undefined) {
    // GET
    resp = await SecureRequestMiddleware<TourStatus[]>(
      async (config: AxiosRequestConfig) => {
        return await axios.get<TourStatus[]>(
          String(API_URL) + String(TOUR_ENDPOINT),
          config
        )
      },
      data
    )
  } else {
    // PUT
    resp = await SecureRequestMiddleware<TourStatus[]>(
      async (config: AxiosRequestConfig, _data: TourStatus[]) => {
        return await axios.put<TourStatus[]>(
          String(API_URL) + String(TOUR_ENDPOINT),
          _data,
          config
        )
      },
      data
    )
    if (resp.status === 200) {
      return data
    }
  }

  if (resp?.status === 200) {
    return resp.data
  }

  return undefined
}
