import StepOne from '@components/RegisterForm/StepOne'
import StepTwo from '@components/RegisterForm/StepTwo'
import { useSignalRWsAnonymous } from '@context/signalr/SignalRWsAnoymousContext'
import { FormikProps, useFormikContext } from 'formik'
import { ClientRegister } from 'models/ClientRegister'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

export enum AccountVerificationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  DEFAULT = 'DEFAULT'
}
export type AccountVerificationStatusType =
  | AccountVerificationStatus.INVALID
  | AccountVerificationStatus.VALID
  | AccountVerificationStatus.DEFAULT
export interface StepParamsType {
  formik: FormikProps<ClientRegister>
  step: number
  isLoading: boolean
  isAmazonAccountValid?: AccountVerificationStatusType
}

export type StepType = ({
  formik,
  step,
  isLoading,
  isAmazonAccountValid
}: StepParamsType) => JSX.Element

export interface Step {
  uuid: string
  label?: string
  description?: string
  condition?: () => boolean
  component: StepType
}

export interface UseWizardFormReturnType {
  steps: Step[]
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  shouldProceed: boolean
  withErrors: boolean
  isSubmitSuccess: boolean
  isAmazonAccountVerifying: boolean
  isAmazonAccountValid: AccountVerificationStatusType
  previousStep: () => void
  nextStep: () => Promise<void>
}

const VERIFICATION_STEP = 1

export function useWizardRegisterForm(
  formik: FormikProps<ClientRegister>
): UseWizardFormReturnType {
  const { formatMessage: f } = useIntl()
  const { errors, setTouched, validateForm, submitForm } =
    useFormikContext<ClientRegister>()
  const hubConnectionContext = useSignalRWsAnonymous()

  const [activeStep, setActiveStep] = useState(0)
  const [shouldProceed, setShouldProceed] = useState(false)
  const [withErrors, setWithErrors] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

  const [isAmazonAccountValid, setIsAmazonAccountValid] = useState(
    AccountVerificationStatus.DEFAULT
  )
  const [isAmazonAccountVerifying, setIsAmazonAccountVerifying] =
    useState(false)

  const stepsKey = useMemo(
    () => ({
      0: {
        name: true,
        email: true,
        password: true,
        referredBy: true,
        isAcceptedTerms: true
      },
      1: { amzPhone: true, amzEmail: true, amzPassword: true },
      2: {} // finish step
    }),
    []
  )

  const steps: Step[] = useMemo(
    () => [
      {
        uuid: uuidv4(),
        label: f({
          id: 'Create your account'
        }),
        description: f({
          id: 'First Step'
        }),
        component: StepOne
      },
      {
        uuid: uuidv4(),
        label: f({
          id: 'Your Amazon info'
        }),
        description: f({
          id: 'Final Step'
        }),
        condition: () =>
          isAmazonAccountValid === AccountVerificationStatus.VALID,
        component: StepTwo
      }
    ],
    [f, isAmazonAccountValid]
  )

  const submit = () => {
    submitForm()
      .then(() => {
        setIsSubmitSuccess(true)
      })
      .catch((error) => {
        throw error
      })
  }

  const previousStep = () => {
    if (activeStep === 0) return
    setActiveStep(activeStep - 1)

    setIsAmazonAccountValid(AccountVerificationStatus.DEFAULT)
  }

  const nextStep = async () => {
    setTouched(stepsKey[activeStep])
    await validateForm()
    await sendAmazonAccountVerification()
    setShouldProceed(true)

    setIsAmazonAccountValid(AccountVerificationStatus.DEFAULT)
  }

  async function invokeMessage() {
    await hubConnectionContext.invoke(
      'VerifyAmazonAccountAsync',
      JSON.stringify({
        email: formik.values.email,
        amzPhone: formik.values.amzPhone,
        amzEmail: formik.values.amzEmail,
        amzPassword: formik.values.amzPassword
      })
    )
    setIsAmazonAccountVerifying(true)
  }

  const sendAmazonAccountVerification = async () => {
    if (activeStep !== VERIFICATION_STEP) return
    await invokeMessage()
  }

  useEffect(() => {
    hubConnectionContext?.on('IsValidAmazonAccount', (connectionId, value) => {
      const isValid: boolean = JSON.parse(value)

      setTouched(stepsKey[activeStep])
      setIsAmazonAccountVerifying(false)

      const verificationStatus = isValid
        ? AccountVerificationStatus.VALID
        : AccountVerificationStatus.INVALID
      setIsAmazonAccountValid(verificationStatus)
      formik.setFieldValue('connectionId', connectionId)
    })
  }, [hubConnectionContext])

  useEffect(() => {
    const keys = Object.keys(stepsKey[activeStep])

    if (!shouldProceed) {
      if (!withErrors) return

      // Find if some field has errors
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (errors[key] !== undefined) {
          return
        }

        if (i === keys.length - 1) {
          setWithErrors(false)
          return
        }
      }
    }

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (errors[key] !== undefined) {
        setShouldProceed(false)
        setWithErrors(true)
        break
      }
      if (i !== keys.length - 1) continue

      if (steps[activeStep]?.condition?.() === false) return

      setActiveStep(activeStep + 1)
      setShouldProceed(false)
      setIsAmazonAccountVerifying(false)
      // Submit handler here
      if (steps.length - 1 === activeStep) {
        submit()
      }
    }
  }, [shouldProceed, activeStep, errors, withErrors, stepsKey, steps])

  return {
    steps,
    activeStep,
    setActiveStep,
    shouldProceed,
    withErrors,
    isSubmitSuccess,
    isAmazonAccountVerifying,
    isAmazonAccountValid,
    previousStep,
    nextStep
  }
}

function ResetPartial(formik: FormikProps<ClientRegister>) {
  formik.values.amzEmail = ''
  formik.values.amzPassword = ''
  formik.values.amzPhone = ''

  return formik
}
