import { API_URL, SCHEDULES_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Schedule } from '@models/Schedule'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig } from 'axios'

export const scheduleRequestService = async (
  token: string,
  data?: Schedule[]
) => {
  if (data === undefined) {
    // GET
    const resp = await SecureRequestMiddleware<ResponseBase<Schedule[]>>(
      async (config: AxiosRequestConfig) => {
        return await axios.get(
          String(API_URL) + String(SCHEDULES_ENDPOINT),
          config
        )
      }
    )

    if (resp.status !== 200) {
      return undefined
    }

    return resp.data.result
  }

  // POST
  const resp = await SecureRequestMiddleware<Schedule[]>(
    async (config: AxiosRequestConfig, _data: Schedule[]) => {
      return await axios.post<Schedule[]>(
        String(API_URL) + String(SCHEDULES_ENDPOINT),
        _data,
        config
      )
    },
    data
  )

  if (resp.status !== 200) {
    return undefined
  }

  return data
}
