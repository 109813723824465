export const IsDate = (d: unknown) => d instanceof Date
export const IsEmpty = (o: unknown) => Object.keys(o).length === 0
export const IsObject = (o: unknown) => o != null && typeof o === 'object'
export const HasOwnProperty = (o: unknown, ...args: unknown[]) =>
  Object.prototype.hasOwnProperty.call(o, ...args)
export const IsEmptyObject = (o: unknown) => IsObject(o) && IsEmpty(o)
export const IsFunction = (
  data: unknown
): data is (...args: unknown[]) => unknown => typeof data === 'function'

export const TypeGuard = <T>(toBeDetermined: any): toBeDetermined is T => {
  if (Boolean(toBeDetermined as T) && Boolean(toBeDetermined.type)) {
    return true
  }
  return false
}

export function ClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function Identity<T>(arg: T): T {
  return arg
}

export function IsString(arg: any): boolean {
  return typeof Identity<string>(arg) === 'string'
}

export function IsValidJson(arg: string): boolean {
  try {
    return JSON.parse(arg) !== undefined
  } catch (error) {
    return false
  }
}

export function Capitalize(str: string): string {
  if (str.length === 0) return ''

  const arr = str.split('')
  arr[0] = arr[0].toUpperCase()

  return arr.join('')
}
