import { IsValidJson } from '@shared/DataValidation'
import { useEffect, useState } from 'react'

export function useStorage<T>(
  key: string,
  type: 'local' | 'session' = 'session'
) {
  const [storedValue, setStoredValue] = useState<T | string>(() => {
    if (typeof window === 'undefined') {
      return ''
    }

    const storage: Storage = type === 'session' ? sessionStorage : localStorage

    const value = storage.getItem(key) ?? ''
    if (value === '') storage.setItem(key, '{}')

    if (IsValidJson(value) === true) {
      return JSON.parse(value) as T
    }

    return ''
  })

  useEffect(() => {
    if (typeof window === 'undefined') return

    const storage: Storage = type === 'session' ? sessionStorage : localStorage

    const value = storage.getItem(key) ?? ''
    if (value === '') storage.setItem(key, '{}')

    if (IsValidJson(value) === true) {
      setStoredValue(JSON.parse(value) as T)
    }
  }, [key, type])

  useEffect(() => {
    if (typeof window === 'undefined') return

    const storage: Storage = type === 'session' ? sessionStorage : localStorage
    storage.setItem(key, JSON.stringify(storedValue))
  }, [storedValue])

  return [storedValue, setStoredValue] as const
}
