import FinishStep from '@components/RegisterForm/FinishStep'
import BButton from '@components/bButton/BButton'
import {
  AccountVerificationStatusType,
  Step,
  useWizardRegisterForm
} from '@hooks/useWizardRegisterForm'
import { Stepper } from '@mantine/core'
import { ClientRegister } from '@models/ClientRegister'
import { Text } from '@nextui-org/react'
import { useAppSelector } from '@store/hooks'
import { FormikProps } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import StepError from './RegisterForm/StepError'
import StepOne from './RegisterForm/StepOne'
import StepTwo from './RegisterForm/StepTwo'

export default function WizardRegisterForm({
  formik,
  isLoading
}: {
  formik: FormikProps<ClientRegister>
  isLoading: boolean
}) {
  const { formatMessage: f } = useIntl()
  const { isFinished, error } = useAppSelector((state) => state.register)
  const {
    steps,
    activeStep,
    setActiveStep,
    withErrors,
    isSubmitSuccess,
    isAmazonAccountVerifying,
    isAmazonAccountValid,
    previousStep,
    nextStep
  } = useWizardRegisterForm(formik)

  const title = f({
    id: 'Complete the form to continue'
  })

  return (
    <Fragment>
      {isFinished === false ? (
        <Fragment>
          <Stepper
            className="mb-8"
            active={activeStep}
            onStepClick={setActiveStep}
            size="md"
            styles={{
              stepBody: {
                display: 'none'
              },
              step: {
                padding: 0
              }
            }}
          >
            {steps.map((step, idx) => (
              <Stepper.Step
                key={idx}
                label={step.label}
                description={step.description}
                allowStepSelect={false}
              >
                <Text size="$lg" className="ml-2 font-bold">
                  {step.label}
                </Text>
                <Text size="$sm" className="ml-2">
                  {step.description}
                </Text>
              </Stepper.Step>
            ))}
          </Stepper>

          <h4 className="mb-6 text-xl font-bold">{title}</h4>

          <div className="min-h-[360px] mb-14">
            <div className="relative grid grid-cols-1 gap-6">
              {error !== undefined && activeStep === steps.length && (
                <div>
                  <h4 className="mb-6 text-xl font-bold text-red-100">
                    {f({
                      id: 'register.error'
                    })}
                  </h4>
                </div>
              )}

              <DisplaySteps
                formik={formik}
                activeStep={activeStep}
                isLoading={isLoading}
                isAmazonAccountValid={isAmazonAccountValid}
                isAmazonAccountVerifying={isAmazonAccountVerifying}
              />
            </div>
          </div>

          <div className="flex justify-between mb-16">
            <BButton
              className="!min-w-[120px] md:!min-w-[180px] lg:!min-w-[120px]"
              isLoading={isLoading}
              onClick={previousStep}
              bordered
              text="Back"
            />
            <>
              {activeStep < steps.length && (
                <BButton
                  className="!min-w-[120px] md:!min-w-[180px] lg:!min-w-[120px]"
                  disabled={withErrors}
                  isLoading={isLoading || isAmazonAccountVerifying}
                  onClick={nextStep}
                  text={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                />
              )}
            </>
          </div>
        </Fragment>
      ) : (
        <FinishStep />
      )}
    </Fragment>
  )
}

function DisplaySteps({
  formik,
  activeStep,
  isLoading,
  isAmazonAccountVerifying,
  isAmazonAccountValid
}: {
  formik: FormikProps<ClientRegister>
  activeStep: number
  isLoading: boolean
  isAmazonAccountVerifying: boolean
  isAmazonAccountValid: AccountVerificationStatusType
}) {
  const steps: Step[] = [
    {
      uuid: uuidv4(),
      component: StepOne
    },
    {
      uuid: uuidv4(),
      component: StepTwo
    },
    {
      uuid: uuidv4(),
      component: StepError
    }
  ]

  const VERIFICATION_STEP = 1

  return (
    <AnimatePresence>
      {steps?.map((step, index) => (
        <>
          {activeStep === index && (
            <motion.article
              className="relative grid grid-cols-1 gap-6"
              key={index}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              viewport={{ once: true }}
            >
              {step.component({
                formik,
                step: activeStep,
                isAmazonAccountValid,
                isLoading:
                  index === VERIFICATION_STEP
                    ? isAmazonAccountVerifying
                    : isLoading
              })}
            </motion.article>
          )}
        </>
      ))}
    </AnimatePresence>
  )
}
