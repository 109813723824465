import { API_URL, EARNS_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { DateRange } from '@models/dtos/DateRange'
import { Earn } from '@models/Earn'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function earnsRequestService(
  token: string,
  data: DateRange
): Promise<Earn[]> {
  const resp = await SecureRequestMiddleware<DateRange, AxiosResponse<Earn[]>>(
    async (config: AxiosRequestConfig) => {
      return await axios.get<Earn[]>(
        String(API_URL) + String(EARNS_ENDPOINT),
        config
      )
    },
    data
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
