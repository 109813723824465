import { DateRange } from '@models/dtos/DateRange'
import { Earn } from '@models/Earn'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { earnsRequestService } from '@services/earns.service'
import { GetStorage } from '@shared/StorageHandler'

interface EarnsState {
  earns: Earn[]
  isLoading: boolean
  error: any
}

const initialState: EarnsState = {
  earns: undefined,
  isLoading: false,
  error: undefined
}

export const earnsRequest = createAsyncThunk(
  'earns/earnsRequest',
  async (data: DateRange, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return earnsRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const earnsSlice = createSlice({
  name: 'earns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(earnsRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(earnsRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.earns = payload
      })
      .addCase(earnsRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default earnsSlice.reducer
