import { WeekDay, WeekDaysFilter } from '@models/WeekDaysFilter'
import { createSlice } from '@reduxjs/toolkit'
import { CustomPayload } from '@store/CustomPayload'

export interface WeekDaysFilterTourState {
  weekDaysFilter: WeekDaysFilter
  hasSelectWeekDay: boolean
  hasPressSelectServiceAreas: boolean
  hasPressClose: boolean
  isLoading: boolean
  error: unknown | undefined
}

const defaultWeekDaysFilter: WeekDaysFilter = {
  daysToWork: [],
  serviceAreaIds: []
}

const initialState: WeekDaysFilterTourState = {
  weekDaysFilter: defaultWeekDaysFilter,
  hasSelectWeekDay: false,
  hasPressSelectServiceAreas: false,
  hasPressClose: false,
  isLoading: false,
  error: undefined
}

const weekDaysFilterTourSlice = createSlice({
  name: 'weekDaysFilterTour',
  initialState,
  reducers: {
    updateDaysToWork(
      state: WeekDaysFilterTourState,
      action: CustomPayload<WeekDay[]>
    ) {
      const weekDaysFilter = state.weekDaysFilter
      weekDaysFilter.daysToWork = action.payload

      state.weekDaysFilter = weekDaysFilter
    },
    updateServiceAreaIds(
      state: WeekDaysFilterTourState,
      action: CustomPayload<string[]>
    ) {
      state.weekDaysFilter.serviceAreaIds = action.payload
    },
    updateHasSelectWeekDay(
      state: WeekDaysFilterTourState,
      action: CustomPayload<boolean>
    ) {
      state.hasSelectWeekDay = action.payload
    },
    updateHasPressSelectServiceAreas(
      state: WeekDaysFilterTourState,
      action: CustomPayload<boolean>
    ) {
      state.hasPressSelectServiceAreas = action.payload
    },
    updateHasPressClose(
      state: WeekDaysFilterTourState,
      action: CustomPayload<boolean>
    ) {
      state.hasPressClose = action.payload
    }
  }
})

export default weekDaysFilterTourSlice.reducer
