export const colors = {
  whitemate: '#DFE0E8',
  gray: {
    50: '#9FA0A6',
    100: '#ACACAC'
  },
  deepblue: {
    50: '#4B4A58',
    100: '#313040',
    150: '#2b2a37',
    200: '#21202E',
    300: '#161524'
  },
  blue: {
    100: '#5DC0F5',
    200: '#1675E3',
    600: '#0072F5'
  },
  green: {
    50: '#53B5BE',
    100: '#86F48D',
    200: '#36B13E',
    250: '#3AAD74',
    300: '#6ABC70'
  },
  purple: {
    50: '#AA90F2',
    100: '#E574CB'
  },
  red: {
    50: '#D84808',
    100: '#D95C5C'
  },
  accents: {
    0: '#F1F3F5',
    1: '#ECEEF0',
    2: '#E6E8EB'
  }
}

export const AVATAR_COLORS = [
  '#FFAD08',
  '#FF3B3E',
  '#A30AD3',
  '#FF632A',
  '#31CD81'
]
