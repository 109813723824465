import { StepParamsType } from '@hooks/useWizardRegisterForm'
import { Fragment } from 'react'
import { useIntl } from 'react-intl'

export default function StepError({ formik, step, isLoading }: StepParamsType) {
  const { formatMessage: f } = useIntl()

  const idStep = 3

  return (
    <Fragment>
      {idStep === step && (
        <article className="flex items-center min-h-[360px]">
          <h2 className="text-center text-2xl mb-8">
            {f({
              id: 'Oops! something happens, try again'
            })}
          </h2>
        </article>
      )}
    </Fragment>
  )
}
