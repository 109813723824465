import { OtpCode } from '@models/OtpCode'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { resendOtpService } from '@services/transactionalApproval/resend_otp.service'
import { verifyOtpService } from '@services/transactionalApproval/verify_otp.service'
import { GetStorage } from '@shared/StorageHandler'

interface ApprovalState {
  isOpen: boolean
  isLoading: boolean
  isResending: boolean
  error: any | undefined
}

const initialState: ApprovalState = {
  isOpen: false,
  isLoading: false,
  isResending: false,
  error: undefined
}

export const resendOtpRequest = createAsyncThunk(
  'transactionalApproval/resendOtpRequest',
  async (_, { rejectWithValue }) => {
    try {
      return resendOtpService()
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const verifyOtpRequest = createAsyncThunk(
  'transactionalApproval/verifyOtpRequest',
  async (data: OtpCode, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return verifyOtpService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const transactionalApproval = createSlice({
  name: 'transactionalApproval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resendOtpRequest.pending, (state) => {
        state.isResending = true
        state.isLoading = false
      })
      .addCase(resendOtpRequest.fulfilled, (state, { payload }) => {
        state.isResending = false
      })
      .addCase(resendOtpRequest.rejected, (state, { payload }) => {
        state.isResending = false
        state.error = payload
      })

      // Verify Otp
      .addCase(verifyOtpRequest.pending, (state) => {
        state.isResending = false
        state.isLoading = true
      })
      .addCase(verifyOtpRequest.fulfilled, (state, { payload }) => {
        state.isResending = false
        state.isLoading = false
      })
      .addCase(verifyOtpRequest.rejected, (state, { payload }) => {
        state.isResending = false
        state.isLoading = false
        state.error = payload
      })
  }
})

export default transactionalApproval.reducer
