import { API_URL, VERIFY_OTP_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { OtpCode } from '@models/OtpCode'
import axios, { AxiosRequestConfig } from 'axios'

export async function verifyOtpService(token: string, data: OtpCode) {
  const resp = await SecureRequestMiddleware<OtpCode>(
    async (config: AxiosRequestConfig, _data: OtpCode) => {
      return await axios.post(
        String(API_URL) + String(VERIFY_OTP_ENDPOINT),
        _data,
        config
      )
    },
    data
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
