import { Alert } from '@mantine/core'
import React, { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
  showErrorMsg?: boolean
  customMsg?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  errorMsg: string
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      errorMsg: ''
    }
  }

  public static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // TODO: add metrics here
    console.error('Uncaught error', error, errorInfo)

    if (this.props?.showErrorMsg ?? false) {
      this.setState({ errorMsg: error.message })
    }
  }

  private resetError() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <Alert
            className="w-3/4 md:w-[480px] mt-40 mx-auto"
            title="Oops!"
            color="pink"
            radius="lg"
            variant="filled"
          >
            <>
              {this.state.errorMsg}
              {this.props.customMsg !== undefined && (
                <div className="custom-msg">{this.props.customMsg}</div>
              )}
            </>
          </Alert>
        </div>
      )
    }

    return this.props.children
  }
}
