import {
  ClassNames,
  HasOwnProperty,
  IsDate,
  IsEmpty,
  IsEmptyObject,
  IsFunction,
  IsObject,
  IsString,
  IsValidJson,
  TypeGuard
} from './DataValidation'
import DeepCopy from './DeepCopy'
import Delay from './Delay'
import Diff from './Diff'
import DiffUpdate from './DiffUpdate'
import IsToday from './IsToday'
import NormalizeDate from './NormalizeDate'
import NormalizeDateTime from './NormalizeDateTime'
import { GetStorage, SetStorage } from './StorageHandler'
import ToMap from './ToMap'

export {
  ClassNames,
  DeepCopy,
  Delay,
  Diff,
  DiffUpdate,
  GetStorage,
  HasOwnProperty,
  IsDate,
  IsEmpty,
  IsEmptyObject,
  IsFunction,
  IsObject,
  IsString,
  IsToday,
  IsValidJson,
  NormalizeDate,
  NormalizeDateTime,
  SetStorage,
  ToMap,
  TypeGuard
}
