import {
  API_URL,
  CLAIM_REWARD_ENDPOINT,
  GENERATE_INVITATION_ENDPOINT,
  INVITATIONS_ENDPOINT,
  VALIDATE_INVITATION_ENDPOINT
} from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Invitation } from '@models/Invitation'
import { Reward } from '@models/Reward'
import { ClaimRewardRequest } from '@models/dtos/ClaimRewardRequest'
import { InvitationDto } from '@models/dtos/InvitationDto'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function referredService(token: string, data?: InvitationDto) {
  const resp = await SecureRequestMiddleware<
    InvitationDto,
    AxiosResponse<Invitation>
  >(async (config: AxiosRequestConfig, _data: InvitationDto) => {
    return await axios.post(
      String(API_URL) + String(GENERATE_INVITATION_ENDPOINT),
      _data,
      config
    )
  }, data)

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export async function invitationsRequestService(token: string) {
  const resp = await SecureRequestMiddleware<ResponseBase<Invitation[]>>(
    async (config: AxiosRequestConfig) => {
      return await axios.get(
        String(API_URL) + String(INVITATIONS_ENDPOINT),
        config
      )
    }
  )

  if (resp.status === 200) {
    return resp.data.result
  }

  return undefined
}

export async function validateInvitationService(data: Partial<InvitationDto>) {
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const resp = await axios.post<Invitation>(
    String(API_URL) + String(VALIDATE_INVITATION_ENDPOINT),
    data,
    axiosOptions
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export async function claimReferrerRewardRequestService(
  token: string,
  data?: ClaimRewardRequest
) {
  const resp = await SecureRequestMiddleware<
    ClaimRewardRequest,
    AxiosResponse<ResponseBase<Reward>>
  >(async (config: AxiosRequestConfig, _data: ClaimRewardRequest) => {
    return await axios.post(
      String(API_URL) + String(CLAIM_REWARD_ENDPOINT),
      _data,
      config
    )
  }, data)

  if (resp.status === 200) {
    return resp.data.result
  }

  return undefined
}
