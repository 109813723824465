/**
  Format date to ISO 8601 `yyyy-MM-ddTHH:mm:ss.fffK`
  @params {Date} d - A date
  @returns {string} A string in format ISO 8601
*/
export default function NormalizeDateTime(d: Date): string {
  d.setHours(0, 0, 0, 0)

  return d.toISOString()
}
