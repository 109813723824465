import { Session } from '@models/Session'
import { Settings } from '@models/Settings'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  changeLanguageRequestService,
  settingsRequestService
} from '@services/settings.service'
import { GetStorage } from '@shared/StorageHandler'

interface SettingsState {
  settings: Settings | undefined
  isLoading: boolean
  error: any
}

const initialState: SettingsState = {
  settings: undefined,
  isLoading: false,
  error: null
}

export const settingsRequest = createAsyncThunk(
  'settings/profileSettingsRequest',
  async (data: Partial<Settings> | undefined, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return settingsRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const changeLanguageRequest = createAsyncThunk(
  'settings/changeLanguageRequest',
  async (data: string, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return changeLanguageRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(settingsRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(settingsRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.settings = payload
      })
      .addCase(settingsRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default settingsSlice.reducer
