/* const fasterMan = require('@assets/images/faster_man_dark.svg') */
const fasterMan = require('@assets/images/faster_man.svg')
const blockPhoto = require('@assets/images/block_photo.png')
const defaultPicture = require('@assets/images/default_picture.png')
const starImg = require('@assets/images/star.png')
const horizonSvg = require('@assets/images/horizon.svg')
const defaultAvatar = require('@assets/images/avatar.jpg')
const easyAndSimple = require('@assets/images/easy_and_simple.svg')
const creditCards = require('@assets/images/credit-cards.png')
const logo = require('@assets/images/logo.png')
const secureLogin = require('@assets/images/secure_login.svg')

const flagEn = require('@assets/images/flag_en.png')
const flagEs = require('@assets/images/flag_es.png')

export {
  blockPhoto,
  creditCards,
  defaultAvatar,
  defaultPicture,
  easyAndSimple,
  fasterMan,
  flagEn,
  flagEs,
  horizonSvg,
  logo,
  secureLogin,
  starImg
}
