import BButton from '@components/bButton/BButton'
import BInput from '@components/BInput'
import BrandLogo from '@components/BrandLogo'
import { blocksIcons } from '@constants/icons'
import { fasterMan } from '@constants/images'
import { Alert } from '@mantine/core'
import { Link } from '@nextui-org/react'
import { ILoginData, loginRequest } from '@store/auth/auth.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { Form, Formik } from 'formik'
import Image from 'next/image'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function SignIn() {
  const dispatch = useAppDispatch()

  const { isLoading, isAuthenticated, error } = useAppSelector(
    (state) => state.auth
  )

  const { formatMessage: f } = useIntl()

  const onSubmitFunc = async (values: ILoginData, actions: any) => {
    dispatch(loginRequest(values))

    if (isAuthenticated === true) {
      actions.resetForm()
    }
  }

  const initializeLoginValue: ILoginData = {
    email: '',
    password: ''
  }

  useEffect(() => {
    if (error !== undefined) {
      console.log('user or password incorrect')
    }
  }, [error])

  const loginFormSchema = yup.object({
    email: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Email is required'
        })
      ),
    password: yup
      .string()
      .min(8, ({ min }) =>
        f(
          {
            id: 'Password must be at least {min} characters'
          },
          { min }
        )
      )
      .required(
        f({
          id: 'Password is required'
        })
      )
  })

  return (
    <div className="flex flex-col items-center">
      <div className="mt-8 pb-8">
        <BrandLogo />
      </div>
      <div className="sign-container lg:flex lg:w-[1000px] w-auto p-4 pt-4 md:px-20 md:pt-4 md:pb-8 lg:px-8 lg:pb-0 lg:pt-16">
        <div className="left-side lg:flex-none lg:w-[600px] lg:pr-[60px]">
          <div className="mb-4 lg:mt-16 md:mb-[60px] flex justify-center">
            <Image
              className="w-[328px] md:w-[440px] h-auto"
              src={fasterMan}
              alt="picture"
            />
          </div>
        </div>
        <div className="right-side w-full lg:pt-16">
          <h1 className="text-lef text-3xl font-normal">
            {f({
              id: 'page.signin.title'
            })}
          </h1>

          <div>
            <h4 className="mb-6 text-xl font-bold">
              {f({ id: 'Sign in to continue' })}
            </h4>
            <Formik
              initialValues={initializeLoginValue}
              validationSchema={loginFormSchema}
              onSubmit={onSubmitFunc}
            >
              {(formik) => (
                <Form className="w-full">
                  <div className="flex flex-col gap-6">
                    <BInput
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange('email')}
                      error={formik.errors.email}
                      contentLeft={blocksIcons.mail}
                      type="email"
                      size="lg"
                      clearable
                    />
                    <BInput
                      label={f({ id: 'Password' })}
                      value={formik.values.password}
                      onChange={formik.handleChange('password')}
                      error={formik.errors.password}
                      contentLeft={blocksIcons.unlock}
                      type="password"
                      size="lg"
                      clearable
                    />

                    <div className="w-full flex justify-end">
                      <Link
                        href="/forgotPassword"
                        className="mb-1 pr-1 text-xs font-bold text-right text-blue-50"
                      >
                        {f({
                          id: 'Forgot password ?'
                        })}
                      </Link>
                    </div>

                    {error !== undefined && (
                      <Alert color="pink" radius="lg">
                        {'user or password are incorrect'}
                      </Alert>
                    )}

                    <div className="w-full flex justify-center mt-4">
                      <BButton
                        isLoading={isLoading}
                        text={f({
                          id: 'Sign in'
                        })}
                        type="submit"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
