export enum TourStatusType {
  Pending,
  Skipped,
  Finished
}

export interface TourStatus {
  status: TourStatusType
  tour: Tour
}

export interface Tour {
  uuid: string
  name: string
}
