import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authProviderRequestService } from '@services/auth.service'
import { GetStorage } from '@shared/StorageHandler'

interface IAuthProviderState {
  isLoading: boolean
  isAuthenticated: boolean
  error: unknown
}

const initialState: IAuthProviderState = {
  isLoading: false,
  isAuthenticated: false,
  error: undefined
}

export interface IAuthProviderData {
  email: string
}

export const authProviderRequest = createAsyncThunk(
  'auth/authProviderRequest',
  async (_, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      const resp = await authProviderRequestService(session.token, {
        email: session.email
      })
      return resp
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const authProviderSlice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authProviderRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(authProviderRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
      })
      .addCase(authProviderRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isAuthenticated = false
        state.error = payload
      })
  }
})

export default authProviderSlice.reducer
