import { API_URL, WS_ANONYMOUS_SYNC_HUB_ENDPOINT } from '@constants/index'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { ProviderProps, SignalRWsContextType } from './SignalRWsTypes'

export const SignalRWsAnonumousContext =
  createContext<SignalRWsContextType>(null)

export default function SignalRWsAnonymousProvider({
  children
}: ProviderProps) {
  const userId = 'anonymous@user.com'
  const query = `?userId=${userId}`

  const anonymousUrl =
    String(API_URL) + String(WS_ANONYMOUS_SYNC_HUB_ENDPOINT) + query

  const [connection, setConnection] = useState<HubConnection>(null)

  const initConnection = useCallback((url: string) => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build()

    hubConnection
      .start()
      .then(() => {
        setConnection(hubConnection)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    initConnection(anonymousUrl)
  }, [anonymousUrl, initConnection])

  return (
    <SignalRWsAnonumousContext.Provider value={connection}>
      {children}
    </SignalRWsAnonumousContext.Provider>
  )
}

export const useSignalRWsAnonymous = () => useContext(SignalRWsAnonumousContext)
