import { ProductTour } from '@models/ProductTour'
import { StepType } from '@reactour/tour'

const steps: StepType[] = [
  {
    selector: '#schedules .schedule-container',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-0'
  },
  {
    selector: '#schedules .b-calendar-container',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-1'
  },
  {
    selector: '#schedules .schedule-time-ranges',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-2',
    stepInteraction: false
  },
  {
    selector: '#schedules .btn-add-time-range',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-3'
  },
  {
    selector: '#schedules .time-range-list-content',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-4'
  },
  {
    selector: '#schedules .btn-remove-time-range',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-5'
  },
  {
    selector: '#schedules .btn-add-time-range',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-6'
  },
  {
    selector: '#schedules .capture-control-container',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-7',
    stepInteraction: false
  },
  {
    selector: '#schedules .filters-safe-mode',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-8'
  },
  {
    selector: '#schedules .filters-min-amount',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-9'
  },
  {
    selector: '#schedules .filters-arrival-time',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-10'
  },
  {
    selector: '#schedules .schedule-container',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-11'
  },
  {
    selector: '#schedules .generals-filters',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-12',
    stepInteraction: false
  },
  {
    selector:
      '#schedules .generals-filters .week-days-container .nextui-checkbox-group',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-13'
  },
  {
    selector: '#schedules .generals-filters .btn-open-service-areas-modal',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-14'
  },
  {
    selector: ' .service-areas-modal-body',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-15'
  },
  {
    selector: '.service-areas-modal .btn-close',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-16'
  },
  {
    selector: '#schedules .schedule-container',
    content: 'tour.5c6049f6-8b79-4475-852e-1fcff9ac89e4.step-17',
    stepInteraction: false
  }
]

const ScheduleProductTour: ProductTour = {
  tourId: '5c6049f6-8b79-4475-852e-1fcff9ac89e4',
  steps
}

export default ScheduleProductTour
