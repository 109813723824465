import { ProductTour } from '@models/ProductTour'
import { StepType } from '@reactour/tour'

const steps: StepType[] = [
  {
    selector: '#referred .referred-container .section-widget',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-0',
    stepInteraction: false
  },
  {
    selector: '#referred .input-email',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-1'
  },
  {
    selector: '#referred .btn-invite',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-2'
  },
  {
    selector: '#referred .referred-status-list ul > li',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-3',
    stepInteraction: false
  },
  {
    selector: '#referred .referred-status-list .actions-wrapper',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-4'
  },
  {
    selector: '#referred .referred-container .section-widget',
    content: 'tour.81ad372a-6aa5-4c81-a300-665a235c10fe.step-5',
    stepInteraction: false
  }
]

const ReferredProductTour: ProductTour = {
  tourId: '81ad372a-6aa5-4c81-a300-665a235c10fe',
  steps
}

export default ReferredProductTour
