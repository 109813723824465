import { useEffect, useState } from 'react'
import { IsValidJson } from './DataValidation'

export function GetStorage<T = string>(
  key: string,
  type: 'local' | 'session' = 'session'
): T | undefined {
  if (typeof window === 'undefined') return undefined

  const storage: Storage = type === 'session' ? sessionStorage : localStorage
  const value = storage.getItem(key) ?? undefined
  if (value === undefined) return undefined

  if (IsValidJson(value)) {
    return JSON.parse(value) as T
  }

  return undefined
}

export function SetStorage<T>(key: string, value: T): void {
  if (typeof window === 'undefined') return
  sessionStorage.setItem(key, JSON.stringify(value))
}
