import { Capture, CaptureStatusType } from '@models/Capture'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState: Capture = {
  status: CaptureStatusType.Stopped,
  safeMode: true,
  blockMinimumLength: 30,
  arrivalTime: moment().add(45, 'minutes').unix(),
  serviceAreas: [],
  startTime: '06:00',
  endTime: '23:59',
  regionId: 0,
  durationTime: 120
}

const captureTourSlice = createSlice({
  name: 'captureTour',
  initialState,
  reducers: {
    updateSafeMode(state, action) {
      state.safeMode = action.payload
    }
  }
})

export default captureTourSlice.reducer
