import { API_URL, REJECT_OFFERS_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { RejectOffersRequest } from '@models/dtos/RejectOffersRequest'
import axios, { AxiosRequestConfig } from 'axios'

export const rejectOffersRequestService = async (data: RejectOffersRequest) => {
  const resp = await SecureRequestMiddleware(
    async (config: AxiosRequestConfig) => {
      return await axios.post(
        String(API_URL) + String(REJECT_OFFERS_ENDPOINT),
        data,
        config
      )
    },
    data
  )

  if (resp.status !== 200) return undefined

  return data.rejectOfferList
}
