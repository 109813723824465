import { useIntl } from 'react-intl'

export default function SignupError() {
  const { formatMessage: f } = useIntl()
  return (
    <p>
      {f(
        {
          id: 'Your invitation link is invalid, please ask to your <b>{text}</b> for a new link'
        },
        {
          text: f({ id: 'referrer' }),
          b: (text) => <b>{text}</b>
        }
      )}
    </p>
  )
}
