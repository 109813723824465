import { API_URL, REWARDS_EARNED_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Reward } from '@models/Reward'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function getRewardsRequestService(
  token: string,
  data?: Reward[]
): Promise<Reward[]> {
  const resp = await SecureRequestMiddleware<
    Reward[],
    AxiosResponse<ResponseBase<Reward[]>>
  >(async (config: AxiosRequestConfig) => {
    return await axios.get<ResponseBase<Reward[]>>(
      String(API_URL) + String(REWARDS_EARNED_ENDPOINT),
      config
    )
  }, data)

  if (resp.status === 200) {
    return resp.data.result
  }

  return undefined
}
