import ErrorBoundary from '@components/ErrorBoundary'
import SignupError from '@components/boundaryErrors/SignupError'
import ForgotPassword from '@pages/forgotPassword'
import SignIn from '@pages/signin'
import SignUp from '@pages/signup'
import { useAppSelector } from '@store/hooks'
import { useRouter } from 'next/router'
import { Fragment, ReactElement } from 'react'

export default function RouteGuard({ children }: { children?: ReactElement }) {
  const router = useRouter()

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

  // TODO: redirect to / if is a guard route
  /* useEffect(() => {
    if (router.pathname !== '/' && isAuthenticated !== true) {
      router.push('/', undefined, { shallow: true })
    }
  }, [router, isAuthenticated]) */

  const unprotected = {
    default: <SignIn />,
    signin: <SignIn />,
    signup: (
      <ErrorBoundary customMsg={<SignupError />}>
        <SignUp />
      </ErrorBoundary>
    ),
    forgotpassword: (
      <ErrorBoundary showErrorMsg>
        <ForgotPassword />
      </ErrorBoundary>
    )
  }

  let componentName = ''
  if (router.pathname !== undefined) {
    // Removing / from pathname "/path" -> "path"
    componentName = String(router.pathname).substring(1).toLowerCase()
  }

  const unprotectedComponent = unprotected[componentName] ?? unprotected.default

  if (isAuthenticated === true) {
    return <div className="main-container">{children}</div>
  }

  return <Fragment>{unprotectedComponent}</Fragment>
}
