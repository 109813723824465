import { ActivitiesStatistics } from '@models/ActivitiesStatistics'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { activitiesStatisticsRequestService } from '@services/activities_statistics.service'

interface ActivitiesStatisticsState {
  statistics: ActivitiesStatistics | undefined
  isLoading: boolean
  isCapturing: boolean
  error: any
}

const initialState: ActivitiesStatisticsState = {
  statistics: {
    todayStatistics: {
      earn: 0,
      captured: 0,
      skipped: 0,
      listed: 0
    }
  },
  isLoading: false,
  isCapturing: false,
  error: undefined
}

export const activitiesStatisticsRequest = createAsyncThunk(
  'activitiesResults/activitiesResultsRequest',
  async (data: ActivitiesStatistics | undefined, { rejectWithValue }) => {
    try {
      return activitiesStatisticsRequestService(data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const activitiesStatisticsSlice = createSlice({
  name: 'activitiesResults',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(activitiesStatisticsRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(activitiesStatisticsRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isCapturing = !state.isCapturing
        state.statistics = payload
      })
      .addCase(activitiesStatisticsRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default activitiesStatisticsSlice.reducer
