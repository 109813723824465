import BrandLogo from '@components/BrandLogo'
import WizardRegisterForm from '@components/WizardRegisterForm'
import { easyAndSimple } from '@constants/images'
import { ClientRegister } from '@models/ClientRegister'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { validateInvitation } from '@store/referred/referred.slice'
import { registerRequest } from '@store/register/register.slice'
import { Form, Formik } from 'formik'
import Image from 'next/image'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function SignUp() {
  const dispatch = useAppDispatch()
  const { isLoading, isFinished } = useAppSelector((state) => state.register)
  const { isInvalid } = useAppSelector((state) => state.referred)
  const { formatMessage: f } = useIntl()

  const onSubmitFunc = (values: ClientRegister, actionsForm: any) => {
    dispatch(registerRequest(values))
  }

  const registerFormValue: ClientRegister = {
    connectionId: '',
    name: '',
    email: '',
    password: '',
    referredBy: '',
    amzPhone: '',
    amzEmail: '',
    amzPassword: '',
    isAcceptedTerms: false
    // nameOnCard: '',
    // cardNumber: '',
    // expirationDate: '',
    // cvv: '',
    // postalCode: ''
  }

  const loginFormSchema = yup.object({
    // Step one
    name: yup
      .string()
      .min(2, ({ min }) =>
        f(
          {
            id: 'Name must be at least {min} characters'
          },
          { min }
        )
      )
      .max(50, ({ max }) =>
        f(
          {
            id: 'Name must have a max of {max} characters'
          },
          { max }
        )
      )
      .required(
        f({
          id: 'Name is required'
        })
      ),
    email: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Email is required'
        })
      ),
    password: yup
      .string()
      .min(8, ({ min }) =>
        f(
          {
            id: 'Password must be at least {min} characters'
          },
          { min }
        )
      )
      .required(
        f({
          id: 'Password is required'
        })
      ),
    referredBy: yup.string().required(
      f({
        id: 'Referred is required'
      })
    ),
    isAcceptedTerms: yup.bool().oneOf(
      [true],
      f({
        id: 'You must read and agree of our Terms and Conditions'
      })
    ),

    // Step two
    amzPhone: yup.string().required(
      f({
        id: 'Phone number of your Amazon account is required'
      })
    ),
    amzEmail: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Email of your Amazon account is required'
        })
      ),
    amzPassword: yup.string().required(
      f({
        id: 'Password of your Amazon account is required'
      })
    )
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const invitationToken = urlParams.get('invitation') ?? undefined

    if (invitationToken !== undefined) {
      dispatch(validateInvitation(invitationToken))
    }
  }, [])

  const errorMsg = () => {
    return f({
      id: 'Your invitation link is invalid, please ask to your "referrer" for a new link'
    })
  }

  useEffect(() => {
    if (isInvalid === true) {
      throw new Error(errorMsg())
    }
  }, [isInvalid])

  return (
    <div className="flex flex-col items-center bg-gradient-to-tr">
      <div className="mt-8 md:mt-20">
        <BrandLogo />
      </div>
      <div className="signup-container lg:flex lg:w-[1000px] w-auto p-4 pt-8 md:p-20 md:pb-8 lg:px-8 lg:pb-0 lg:pt-16">
        {isFinished === false && (
          <div className="left-side lg:flex-none lg:w-[600px] lg:pr-[60px]">
            <div className="flex flex-col md:flex-col-reverse mb-10">
              <div className="mb-10 md:pt-8">
                <Image
                  className="h-full"
                  src={easyAndSimple}
                  alt={f({
                    id: 'picture showing a simple and easy app use'
                  })}
                  width={540}
                  /* height={280} */
                />
              </div>
              <div>
                <h2 className="text-lef text-3xl font-normal">
                  {f({
                    id: 'page.signin.title'
                  })}
                </h2>
                <h1 className="text-xl md:text-2xl md:mb-8">
                  {f({
                    id: 'Register For Free Trial'
                  })}
                </h1>
              </div>
            </div>
          </div>
        )}
        <div className="right-side w-full lg:pt-16">
          <Formik
            initialValues={registerFormValue}
            validationSchema={loginFormSchema}
            onSubmit={onSubmitFunc}
          >
            {(formik) => (
              <Form>
                <WizardRegisterForm formik={formik} isLoading={isLoading} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
