import { ForgotPasswordDto } from '@models/dtos/ForgotPasswordDto'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  forgotPasswordService,
  validateResetTokenService
} from '@services/forgotPassword.service'

interface forgotPasswordState {
  isValidToken: boolean | undefined
  isLoading: boolean
  isSuccess: boolean | undefined
  error: any | undefined
}

const initialState: forgotPasswordState = {
  isValidToken: undefined,
  isLoading: false,
  isSuccess: undefined,
  error: undefined
}

export interface ValidateResetTokenRequest {
  resetToken: string
}

export const validateResetTokenRequest = createAsyncThunk(
  'forgotPassword/validateResetToken',
  async (data: ValidateResetTokenRequest, { rejectWithValue }) => {
    try {
      return validateResetTokenService(data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const forgotPasswordRequest = createAsyncThunk(
  'forgotPassword/forgotPassword',
  async (data: ForgotPasswordDto, { rejectWithValue }) => {
    try {
      return forgotPasswordService(data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateResetTokenRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(validateResetTokenRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isValidToken = payload !== undefined ?? false
      })
      .addCase(validateResetTokenRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isValidToken = false
        state.error = payload
      })
      .addCase(forgotPasswordRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(forgotPasswordRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = payload !== undefined ?? false
      })
      .addCase(forgotPasswordRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = false
        state.error = payload
      })
  }
})

export default forgotPasswordSlice.reducer
