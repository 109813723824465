import {
  API_URL,
  CHANGE_LANGUAGE_ENDPOINT,
  PROFILE_SETTINGS_ENDPOINT
} from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Language, Settings } from '@models/Settings'
import DiffUpdate from '@shared/DiffUpdate'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const settingsRequestService = async (
  token: string,
  data?: Partial<Settings>,
  prevValue?: Settings
) => {
  let resp: AxiosResponse<Settings, any> | undefined

  if (data !== undefined) {
    // PUT
    resp = await SecureRequestMiddleware<Settings>(
      async (config: AxiosRequestConfig, _data: Settings) => {
        return await axios.put<Settings>(
          String(API_URL) + String(PROFILE_SETTINGS_ENDPOINT),
          _data,
          config
        )
      }
    )

    if (resp.status === 200) {
      return DiffUpdate(data, prevValue)
    }

    return undefined
  }

  // GET
  resp = await SecureRequestMiddleware<Settings>(
    async (config: AxiosRequestConfig) => {
      return await axios.get<Settings>(
        String(API_URL) + String(PROFILE_SETTINGS_ENDPOINT),
        config
      )
    }
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export const avatarChangeRequestService = async (data: File, token: string) => {
  // TODO: create a UPDATE request to change avatar

  return undefined
}

export const changeLanguageRequestService = async (
  token: string,
  data: string
) => {
  const resp = await SecureRequestMiddleware<Language>(
    async (config: AxiosRequestConfig, _data: Language) => {
      return await axios.put<Language>(
        String(API_URL) + String(CHANGE_LANGUAGE_ENDPOINT),
        _data,
        config
      )
    }
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
