import {
  API_URL,
  FORGOT_PASSWORD_ENDPOINT,
  VALIDATE_RESET_TOKEN_ENDPOINT
} from '@constants/index'
import { ForgotPasswordDto } from '@models/dtos/ForgotPasswordDto'
import { ValidateResetTokenRequest } from '@store/forgotPassword/forgotPassword.slice'
import axios from 'axios'

export async function validateResetTokenService(
  data: ValidateResetTokenRequest
) {
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const resp = await axios.post(
    String(API_URL) + String(VALIDATE_RESET_TOKEN_ENDPOINT),
    data,
    axiosOptions
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export async function forgotPasswordService(data: ForgotPasswordDto) {
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    },
    params: data
  }

  const resp = await axios.get(
    String(API_URL) + String(FORGOT_PASSWORD_ENDPOINT),
    axiosOptions
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
