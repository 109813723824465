import { colors } from '@constants/colors'
import { useStorage } from '@hooks/useStorage'
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider
} from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { NextUIProvider, createTheme } from '@nextui-org/react'
import { store } from '@store/store'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { CustomIntlProvider } from './CustomIntlContext'
import SignalRWsAnonymousProvider from './signalr/SignalRWsAnoymousContext'

export default function Providers({ children }: { children: React.ReactNode }) {
  const [storedValue] = useStorage<string>('theme', 'local')
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light')
  const toggleColorSchemeNextJs = (value?: ColorScheme) => {
    if (value !== undefined) {
      setColorScheme(value)
      return
    }

    setColorScheme(colorScheme)
  }

  useEffect(() => {
    setColorScheme(storedValue === 'dark' ? 'dark' : 'light')
  }, [storedValue])

  return (
    <Provider store={store}>
      <SignalRWsAnonymousProvider>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorSchemeNextJs}
        >
          <MantineProvider
            theme={{
              colorScheme
            }}
          >
            <NotificationsProvider>
              <NextThemesProvider
                defaultTheme={'system'}
                storageKey={'theme'}
                enableColorScheme={true}
                themes={['light', 'dark']}
                attribute="class"
                value={{
                  light: lightTheme.className,
                  dark: darkTheme.className
                }}
              >
                <NextUIProvider>
                  <CustomIntlProvider>{children}</CustomIntlProvider>
                </NextUIProvider>
              </NextThemesProvider>
            </NotificationsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </SignalRWsAnonymousProvider>
    </Provider>
  )
}

const lightTheme = createTheme({
  type: 'light',
  className: 'light',
  theme: {
    colors: {
      // background: '#F7F2FD',
      accents3: '#fff',
      red600: colors.red[100]
    }
  }
})

const darkTheme = createTheme({
  type: 'dark',
  className: 'dark',
  theme: {
    colors: {
      background: colors.deepblue[300],
      gray50: colors.deepblue[100],
      accents1: colors.deepblue[150],
      accents2: colors.deepblue[100],
      accents3: colors.deepblue[200],
      red600: colors.red[100]
    }
  }
})
