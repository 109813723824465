import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import authReducer from './auth/auth.slice'
import authProviderReducer from './auth/authProvider.slice'
import changePwdReducer from './changePassword/changePassword.slice'
import activitiesStatisticsReducer from './dashboard/activities_statistics.slice'
import captureReducer from './dashboard/capture.slice'
import earnsReducer from './dashboard/earns.slice'
import forgotPasswordReducer from './forgotPassword/forgotPassword.slice'
import languagesReducer from './languages/languages.slice'
import navigationReducer from './navigation/navigation.slice'
import notificationsReducer from './notifications/notifications.slice'
import productTourReducer from './product_tour/product_tour.slice'
import captureProductTourReducer from './productTour/capture/capture.slice'
import referredReducer from './referred/referred.slice'
import registerReducer from './register/register.slice'
import rewardsReducer from './rewards/rewards.slice'
import scheduleReducer from './schedule/schedule.slice'
import captureTourReducer from './scheduleTour/capture.slice'
import scheduleTourReducer from './scheduleTour/schedule.slice'
import serviceAreaReducer from './serviceArea/serviceArea.slice'
import settingsReducer from './settings/settings.slice'
import transactionalApprovalReducer from './transactionalApproval/transactionalApproval.slice'
import weekDaysFilterReducer from './weekDaysFilter/weekDaysFilter.slice'
import weekDaysFilterTourReducer from './weekDaysFilterTour/weekDaysFilter.slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    authProvider: authProviderReducer,
    register: registerReducer,
    capture: captureReducer,
    activitiesStatistics: activitiesStatisticsReducer,
    earns: earnsReducer,
    settings: settingsReducer,
    schedules: scheduleReducer,
    scheduleTour: scheduleTourReducer,
    captureTour: captureTourReducer,
    productTour: productTourReducer,
    serviceAreas: serviceAreaReducer,
    navigation: navigationReducer,
    referred: referredReducer,
    notifications: notificationsReducer,
    languages: languagesReducer,
    forgotPassword: forgotPasswordReducer,
    rewards: rewardsReducer,
    changePwd: changePwdReducer,
    weekDaysFilter: weekDaysFilterReducer,
    weekDaysFilterTour: weekDaysFilterTourReducer,
    captureProductTour: captureProductTourReducer,
    transactionalApproval: transactionalApprovalReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = unknown> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>
