import { API_URL, SERVICE_AREAS_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { ServiceArea } from '@models/ServiceArea'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function serviceAreaRequestService(
  token: string,
  regionId: number
): Promise<ServiceArea[]> {
  interface params {
    regionId: string
  }

  const resp = await SecureRequestMiddleware<
    ServiceArea[],
    AxiosResponse<ServiceArea[]>,
    params
  >(
    async (config: AxiosRequestConfig) => {
      return await axios.get<ServiceArea[]>(
        String(API_URL) + String(SERVICE_AREAS_ENDPOINT),
        config
      )
    },
    undefined,
    { regionId: regionId.toString() }
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
