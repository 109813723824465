export enum CaptureStatusType {
  Stopped,
  Started,
  Paused
}

export interface Capture {
  status: CaptureStatusType
  safeMode: boolean
  blockMinimumLength: number
  arrivalTime: number
  serviceAreas: string[]
  regionId: number
  startTime: string
  endTime: string
  durationTime: number
}

export interface Block {
  id: string
  station: string
  address: string
  amount: number
}
