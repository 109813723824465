import { Language } from '@models/Settings'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { languagesRequestService } from '@services/languages.service'

interface LanguagesState {
  languages: Language[] | undefined
  isLoading: boolean
  error: any
}

const initialState: LanguagesState = {
  languages: undefined,
  isLoading: false,
  error: null
}

export const languagesRequest = createAsyncThunk(
  'languages/languagesRequest',
  async (data: undefined, { rejectWithValue }) => {
    try {
      return languagesRequestService()
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(languagesRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(languagesRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.languages = payload
      })
      .addCase(languagesRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default languagesSlice.reducer
