import { Schedule } from '@models/Schedule'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import NormalizeDateTime from '@shared/NormalizeDateTime'
import {
  addTimeRange,
  addEventRange,
  removeEventRange,
  removeTimeRange,
  updateCaptureSettings,
  updateTimeRange
} from './schedule.reducers'

export interface ScheduleTourState {
  schedules: Schedule[] | undefined
  selectedDate: string
  selectedSchedule: Schedule | undefined
  hasPressSelectDay: boolean
  hasPressAddEvent: boolean
  hasPressRemoveEvent: boolean
  hasSelectWeekDay: boolean
  isLoading: boolean
  error: unknown | undefined
}

export interface SchedulePayload<T> {
  payload: T
  type: string
}

const initialState: ScheduleTourState = {
  schedules: [],
  selectedDate: NormalizeDateTime(new Date()),
  selectedSchedule: undefined,
  hasPressSelectDay: false,
  hasPressAddEvent: false,
  hasPressRemoveEvent: false,
  hasSelectWeekDay: false,
  isLoading: false,
  error: undefined
}

const scheduleTourSlice = createSlice({
  name: 'scheduleTour',
  initialState,
  reducers: {
    update(state: ScheduleTourState, action: SchedulePayload<Schedule[]>) {
      state.schedules = action.payload
    },
    changeCurrentDate(
      state: ScheduleTourState,
      action: SchedulePayload<string>
    ) {
      const formatedDate = NormalizeDateTime(new Date(action.payload))
      state.selectedDate = formatedDate

      const foundedSchedule = state.schedules?.find(
        (s) => NormalizeDateTime(new Date(s.dateId)) === formatedDate
      )
      state.selectedSchedule = foundedSchedule
    },
    updateHasPressSelectDay(
      state: ScheduleTourState,
      action: SchedulePayload<boolean>
    ) {
      state.hasPressSelectDay = action.payload
    },
    updateHasPressAddEvent(
      state: ScheduleTourState,
      action: SchedulePayload<boolean>
    ) {
      state.hasPressAddEvent = action.payload
    },
    updateHasPressRemoveEvent(
      state: ScheduleTourState,
      action: SchedulePayload<boolean>
    ) {
      state.hasPressRemoveEvent = action.payload
    },
    updateHasSelectWeekDay(
      state: ScheduleTourState,
      action: SchedulePayload<boolean>
    ) {
      state.hasSelectWeekDay = action.payload
    },
    addTimeRange,
    addEventRange,
    removeEventRange,
    removeTimeRange,
    updateTimeRange,
    updateCaptureSettings
  }
})

export default scheduleTourSlice.reducer
