import { Capture } from '@models/Capture'
import { TimeRangeDto } from '@models/dtos/ScheduleDateDto'
import { Schedule } from '@models/Schedule'
import { ServiceArea } from '@models/ServiceArea'
import NormalizeDateTime from '@shared/NormalizeDateTime'
import { defaultCapture } from '@store/dashboard/capture.slice'
import {
  SchedulePayload,
  ScheduleTourState
} from '@store/scheduleTour/schedule.slice'
import { v4 as uuidv4 } from 'uuid'

const initDate = NormalizeDateTime(new Date())
const initTimeRanges: TimeRangeDto[] = []

export const initSchedule: Schedule = {
  dateId: initDate,
  timeRanges: initTimeRanges,
  capture: defaultCapture
}

function createTimeRange() {
  // FIXME: make a constant from start and end values
  const defaultTimeRange: TimeRangeDto = {
    uuid: uuidv4(),
    start: '00:00',
    end: '23:59'
  }

  return defaultTimeRange
}

export function addTimeRange(state: ScheduleTourState) {
  const foundIdx = state.schedules.findIndex(
    (s) => s.dateId === state.selectedDate
  )

  const defaultSchedule: Schedule = {
    dateId: state.selectedDate,
    timeRanges: [],
    capture: defaultCapture
  }

  const newSchedule =
    state.schedules?.[foundIdx] !== undefined
      ? state.schedules[foundIdx]
      : defaultSchedule

  if (newSchedule.timeRanges.length === 5) return

  newSchedule.timeRanges.push(createTimeRange())

  // update selected schedule
  state.selectedSchedule = newSchedule

  // if exist then update schedule
  if (foundIdx >= 0) {
    state.schedules[foundIdx] = newSchedule
    return
  }

  // add new schedule
  state.schedules.push(newSchedule)
}

export function addEventRange(
  state: ScheduleTourState,
  action: SchedulePayload<string[]>
) {
  if (action.payload === undefined) return

  action.payload.forEach((date) => {
    const newSchedule: Schedule = {
      dateId: date,
      timeRanges: [],
      capture: defaultCapture
    }

    const foundIdx = state.schedules.findIndex((s) => s.dateId === date)
    if (foundIdx > -1) return

    newSchedule.timeRanges.push(createTimeRange())

    state.schedules.push(newSchedule)
  })
}

export function removeEventRange(
  state: ScheduleTourState,
  action: SchedulePayload<string[]>
) {
  if (action.payload === undefined) return

  const schedules: Schedule[] = [...state.schedules]

  action.payload.forEach((date) => {
    const foundIdx = schedules.findIndex((s) => s.dateId === date)
    if (foundIdx === -1) return

    schedules.splice(foundIdx, 1)
  })

  return {
    ...state,
    schedules
  }
}

export function removeTimeRange(
  state: ScheduleTourState,
  action: SchedulePayload<TimeRangeDto>
) {
  if (state.selectedSchedule.timeRanges === undefined) return

  state.selectedSchedule.timeRanges.forEach((timeRange, i) => {
    if (timeRange.uuid !== action.payload.uuid) return

    state.selectedSchedule.timeRanges.splice(i, 1)

    state.schedules.forEach((schedule, index) => {
      if (schedule.dateId === state.selectedSchedule?.dateId) {
        // Delete if no timeRages
        if (state.selectedSchedule.timeRanges.length === 0) {
          state.selectedSchedule = undefined
          state.schedules.splice(index, 1)
          return
        }

        state.schedules[index] = state.selectedSchedule
      }
    })
  })
}

export function updateTimeRange(
  state: ScheduleTourState,
  action: SchedulePayload<TimeRangeDto>
) {
  for (const timeRange of state.selectedSchedule.timeRanges) {
    if (timeRange.uuid !== action.payload.uuid) continue

    timeRange.start = action.payload.start
    timeRange.end = action.payload.end

    state.schedules.forEach((schedule, index) => {
      if (schedule.dateId === state.selectedSchedule.dateId) {
        state.schedules[index] = state.selectedSchedule
      }
    })
  }
}

export function updateCaptureSettings(
  state: ScheduleTourState,
  action: SchedulePayload<Capture>
) {
  if (action.payload === undefined) return
  if (state.schedules === undefined) return
  if (state.selectedSchedule === undefined) return

  state.selectedSchedule.capture = action.payload

  state.schedules.forEach((schedule, index) => {
    if (schedule.dateId === state.selectedSchedule.dateId) {
      state.schedules[index] = state.selectedSchedule
    }
  })
}
