import { Input, InputProps, useInput } from '@nextui-org/react'
import { ChangeEvent, HTMLInputTypeAttribute } from 'react'

export interface CustomInputProps {
  type?: HTMLInputTypeAttribute | undefined
  error?: string
  value: string | number
  onlyNumbers?: boolean
  onChange?: string | ((e: ChangeEvent<HTMLInputElement>) => void)
}

export type BInputProps = Partial<InputProps> & CustomInputProps

export default function BInput({
  type,
  error,
  value,
  onlyNumbers,
  onChange,
  ...props
}: BInputProps) {
  const { reset, bindings } = useInput('')

  const inputTypes = new Map<string, any>([['password', Input.Password]])

  const getInputType = () => {
    if (type === undefined) return Input

    return inputTypes.get(type) ?? Input
  }

  const CustomComponent = getInputType()

  const acceptOnlyNumbers = (e: KeyboardEvent) => {
    if (e.defaultPrevented) return

    if (e.shiftKey) {
      e.preventDefault()
      return
    }

    const codes = [
      'Digit1',
      'Digit2',
      'Digit3',
      'Digit4',
      'Digit5',
      'Digit6',
      'Digit7',
      'Digit8',
      'Digit9',
      'Digit0',
      'Delete',
      'Backspace',
      'Tab',
      'Enter',
      'ArrowDown',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      'Home',
      'End'
    ]

    if (!codes.includes(e.code)) {
      e.preventDefault()
    }
  }

  const onKeyDown = onlyNumbers === true ? acceptOnlyNumbers : props.onKeyDown

  return (
    <CustomComponent
      {...props}
      {...bindings}
      onClearClick={reset}
      color={error === undefined ? 'default' : 'error'}
      helperColor={error === undefined ? 'default' : 'error'}
      helperText={error}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
}
