export interface CaptureSignalStatusMessage {
  signal: CaptureSignalStatusType
  reason: string
}

export enum CaptureSignalStatusType {
  Stopped = 'Stopped',
  Started = 'Started',
  Paused = 'Paused'
}
