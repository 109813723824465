import { Schedule } from '@models/Schedule'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { scheduleRequestService } from '@services/schedule.service'
import NormalizeDateTime from '@shared/NormalizeDateTime'
import { GetStorage } from '@shared/StorageHandler'
import moment from 'moment'
import {
  addTimeRange,
  addEventRange,
  removeEventRange,
  removeTimeRange,
  updateCaptureSettings,
  updateTimeRange
} from './schedule.reducers'

export interface ScheduleState {
  schedules: Schedule[] | undefined
  selectedDate: string
  selectedSchedule: Schedule | undefined
  isLoading: boolean
  error: unknown | undefined
}

export interface SchedulePayload<T> {
  payload: T
  type: string
}

const initialState: ScheduleState = {
  schedules: undefined,
  selectedDate: NormalizeDateTime(new Date()),
  selectedSchedule: undefined,
  isLoading: false,
  error: undefined
}

export const scheduleRequest = createAsyncThunk(
  'schedule/scheduleRequest',
  async (data: Schedule[] | undefined, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return scheduleRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const scheduleRequestAndSelect = createAsyncThunk(
  'schedule/scheduleRequestAndSelect',
  async (data: Schedule[] | undefined, { dispatch, getState }) => {
    await dispatch(scheduleRequest(undefined))

    const { schedules } = getState() as { schedules: ScheduleState }
    dispatch({
      type: 'schedule/changeCurrentDate',
      payload: schedules.selectedDate
    })
  }
)

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    update(state: ScheduleState, action: SchedulePayload<Schedule[]>) {
      state.schedules = action.payload
    },
    changeCurrentDate(state: ScheduleState, action: SchedulePayload<string>) {
      const formatedDate = NormalizeDateTime(new Date(action.payload))
      state.selectedDate = formatedDate

      const foundedSchedule = state.schedules?.find(
        (s) => NormalizeDateTime(new Date(s.dateId)) === formatedDate
      )

      state.selectedSchedule = foundedSchedule
    },
    addTimeRange,
    addEventRange,
    removeEventRange,
    removeTimeRange,
    updateTimeRange,
    updateCaptureSettings
  },
  extraReducers: (builder) => {
    builder
      .addCase(scheduleRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(scheduleRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.schedules = payload
      })
      .addCase(scheduleRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default scheduleSlice.reducer
