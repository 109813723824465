import Providers from '@context/Providers'
import RouteGuard from '@lib/routeGuard'
import { AppProps } from 'next/app'
import '../styles/global.scss'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Providers>
      <RouteGuard>
        <Component {...pageProps} />
      </RouteGuard>
    </Providers>
  )
}
