import { ClaimRewardRequest } from '@models/dtos/ClaimRewardRequest'
import { InvitationDto } from '@models/dtos/InvitationDto'
import { Reward } from '@models/Reward'
import { Invitation } from '@models/Invitation'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  claimReferrerRewardRequestService,
  invitationsRequestService,
  referredService,
  validateInvitationService
} from '@services/referred.service'
import { GetStorage } from '@shared/StorageHandler'
import { CustomPayload } from '@store/CustomPayload'

interface ReferredState {
  invitations: Invitation[] | undefined
  currentInvitation: Invitation | undefined
  reward: Reward | undefined
  isLoading: boolean
  isInvalid: boolean
  error: any | undefined
}

const initialState: ReferredState = {
  invitations: undefined,
  currentInvitation: undefined,
  reward: undefined,
  isLoading: false,
  isInvalid: false,
  error: undefined
}

export const generateInvitation = createAsyncThunk(
  'referred/generateInvitation',
  async (data: InvitationDto, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return referredService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const validateInvitation = createAsyncThunk(
  'referred/validateInvitation',
  async (invitationToken: string, { rejectWithValue }) => {
    try {
      const invitation: InvitationDto = {
        token: invitationToken
      }

      return validateInvitationService(invitation)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getInvitations = createAsyncThunk(
  'referred/getInvitations',
  async (_, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return invitationsRequestService(session.token)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const claimReferrerReward = createAsyncThunk(
  'referred/claimReferrerReward',
  async (data: ClaimRewardRequest, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return claimReferrerRewardRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const referred = createSlice({
  name: 'referred',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateInvitation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(generateInvitation.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.currentInvitation = payload
      })
      .addCase(generateInvitation.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })

      .addCase(validateInvitation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(validateInvitation.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isInvalid = payload === undefined ?? false
        state.currentInvitation = payload
      })
      .addCase(validateInvitation.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isInvalid = true
        state.error = payload
      })

      .addCase(getInvitations.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getInvitations.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.invitations = payload
        state.currentInvitation = undefined
      })
      .addCase(getInvitations.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })

      // 'referred/claimReferrerReward'
      .addCase(claimReferrerReward.pending, (state) => {
        state.isLoading = true
      })
      .addCase(claimReferrerReward.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.reward = payload
      })
      .addCase(claimReferrerReward.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default referred.reducer
