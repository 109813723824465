import { API_URL, WEEK_DAYS_FILTER_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { WeekDaysFilter } from '@models/WeekDaysFilter'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig } from 'axios'

export const weekDaysFilterRequestService = async (
  token: string,
  data?: WeekDaysFilter
) => {
  if (data === undefined) {
    // GET
    const resp = await SecureRequestMiddleware<ResponseBase<WeekDaysFilter>>(
      async (config: AxiosRequestConfig) => {
        return await axios.get<ResponseBase<WeekDaysFilter>>(
          String(API_URL) + String(WEEK_DAYS_FILTER_ENDPOINT),
          config
        )
      }
    )

    if (resp.status !== 200) {
      return undefined
    }

    return resp.data.result
  }

  // POST
  const resp = await SecureRequestMiddleware<WeekDaysFilter>(
    async (config: AxiosRequestConfig, _data: WeekDaysFilter) => {
      return await axios.post(
        String(API_URL) + String(WEEK_DAYS_FILTER_ENDPOINT),
        _data,
        config
      )
    },
    data
  )

  if (resp.status !== 200) {
    return undefined
  }

  return data
}
