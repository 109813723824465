import {
  HasOwnProperty,
  IsDate,
  IsEmptyObject,
  IsObject
} from './DataValidation'

export default function Diff<T>(l: T, r: T): Partial<T> {
  if (l === r) return

  if (!IsObject(l) || !IsObject(r)) return r // return updated right side

  const deletedValues = Object.keys(l).reduce((acc, key) => {
    if (HasOwnProperty(r, key) === false) {
      acc[key] = {}
    }

    return acc
  }, {})

  if (IsDate(l) || IsDate(r)) {
    if (l.valueOf() === r.valueOf()) return
    return r
  }

  return Object.keys(r).reduce((acc, key) => {
    if (HasOwnProperty(l, key) === false) {
      acc[key] = r[key] // return added r key
      return acc
    }

    const difference = Diff(l[key], r[key])

    // If the difference is empty, and the lhs is an empty object or the rhs is not an empty object
    if (
      IsEmptyObject(difference) &&
      !IsDate(difference) &&
      (IsEmptyObject(l[key]) || !IsEmptyObject(r[key]))
    )
      return acc // return no diff

    acc[key] = difference // return updated key
    return acc // return updated key
  }, deletedValues)
}

export function MatrixDiffExclude(l: string[][], r: string[][]): string[] {
  if (l === undefined || r === undefined) return

  const leftFlat = l.flat()
  const rightFlat = r.flat()

  const diff: string[] = []

  leftFlat.forEach((value) => {
    if (!rightFlat.includes(value)) {
      diff.push(value)
    }
  })

  return diff
}
