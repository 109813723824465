import CaptureProductTour from '@components/ProductTour/Capture/CaptureTourSteps'
import ReferredProductTour from '@components/ProductTour/Referred/ReferredTourSteps'
import ScheduleProductTour from '@components/ProductTour/Schedule/ScheduleTourSteps'
import { Capture, CaptureStatusType } from '@models/Capture'
import { Offer, RateInfo } from '@models/Offer'
import { Session } from '@models/Session'
import { TourStatus, TourStatusType } from '@models/TourStatus'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { tourRequestService } from '@services/tour.service'
import { GetStorage } from '@shared/StorageHandler'
import { CustomPayload } from '@store/CustomPayload'

interface ProductTourState {
  isShowTour: boolean
  isShowTourProgress: boolean
  tourStatuses: TourStatus[] | undefined
  activeTourId: string
  activeTours: Map<string, boolean>
  offers: Offer[] | undefined
  isLoading: boolean
  isCapturing: boolean
  isAutoContinue: boolean
  error: any | undefined
}

const initialState: ProductTourState = {
  isShowTour: false,
  isShowTourProgress: false,
  tourStatuses: undefined,
  activeTourId: String(),
  activeTours: new Map<string, boolean>(),
  offers: undefined,
  isLoading: false,
  isCapturing: false,
  isAutoContinue: false,
  error: undefined
}

export const RouteIds = new Map<string, number>([
  [CaptureProductTour.tourId, 0],
  [ScheduleProductTour.tourId, 1],
  [ReferredProductTour.tourId, 2]
])

export const captureRequest = createAsyncThunk(
  'product_tour/captureRequest',
  async (data: Partial<Capture>, { rejectWithValue }) => {
    if (data.status === CaptureStatusType.Stopped) return

    const rateInfo: RateInfo = {
      priceDetails: '',
      currency: 'USD',
      priceAmount: 32,
      pricingUxVersion: 'V2',
      projectedTips: 34,
      surgeMultiplier: ''
    }

    const offers: Offer[] = []

    offers.push({
      offerId: 'b58c1567-b85f-40de-9511-f26690d417d5',
      serviceAreaId: '488e8dbe-fcd4-49d6-85df-baefe8bdf2f8',
      expirationDate: '2023-02-02T06:36:53.917399+00:00',
      endTime: '2023-02-02T05:36:53.917399+00:00',
      rateInfo,
      offerType: 'NON_EXCLUSIVE'
    })

    return offers
  }
)

export const tourRequest = createAsyncThunk(
  'product_tour/tourRequest',
  async (data: TourStatus[] | undefined, { rejectWithValue }) => {
    try {
      return tourRequestService(data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const finishTourAndUpdate = createAsyncThunk(
  'product_tour/finishTourAndUpdate',
  async (data: string, { dispatch, getState }) => {
    dispatch({ type: 'product_tour/finishTour', payload: data })

    const { productTour } = getState() as { productTour: ProductTourState }
    await dispatch(tourRequest(productTour.tourStatuses))
  }
)

export const retakeTourRequest = createAsyncThunk(
  'product_tour/retakeTourRequest',
  async (data: string, { dispatch, getState }) => {
    dispatch({ type: 'product_tour/retakeTour', payload: data })

    const { productTour } = getState() as { productTour: ProductTourState }
    await dispatch(tourRequest(productTour.tourStatuses))
  }
)

const productTour = createSlice({
  name: 'product_tour',
  initialState,
  reducers: {
    cancelOffer(state: ProductTourState, action: CustomPayload<Offer>) {
      const filteredList = state.offers?.filter(
        (item) => item.offerId !== action.payload.offerId
      )

      state.offers = filteredList
      state.isAutoContinue = true
    },
    toggleIsAutoContinue(state: ProductTourState) {
      state.isAutoContinue = !state.isAutoContinue
    },
    beginTour(state: ProductTourState, action: CustomPayload<string>) {
      if (state.tourStatuses === undefined) return

      const TOUR_UUID = action.payload

      // Change active tour
      state.activeTourId = TOUR_UUID
    },
    changeActiveTour(state: ProductTourState, action: CustomPayload<string>) {
      state.activeTourId = action.payload
    },
    finishTour(state: ProductTourState, action: CustomPayload<string>) {
      if (state.tourStatuses === undefined) return

      const idx = state.tourStatuses.findIndex(
        (x) => x.tour.uuid === action.payload
      )
      if (idx < 0) return

      state.tourStatuses[idx].status = TourStatusType.Finished

      // Active tour progression
      state.isShowTourProgress = true
    },
    retakeTour(state: ProductTourState, action: CustomPayload<string>) {
      if (state.tourStatuses === undefined) return

      const idx = state.tourStatuses.findIndex(
        (x) => x.tour.uuid === action.payload
      )
      if (idx < 0) return

      state.tourStatuses[idx].status = TourStatusType.Pending
    },
    updateShowTourProgression(
      state: ProductTourState,
      action: CustomPayload<boolean>
    ) {
      state.isShowTourProgress = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(captureRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(captureRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isCapturing = !state.isCapturing
        state.isAutoContinue = !state.isAutoContinue
        state.offers = payload
      })
      .addCase(captureRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      .addCase(tourRequest.pending, (state) => {
        state.isLoading = true
        state.isShowTour = false
      })
      .addCase(tourRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.tourStatuses = payload
        state.isShowTour = false
        state.activeTours = convertToursToMap(payload)
      })
      .addCase(tourRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

function convertToursToMap(tourStatuses: TourStatus[]): Map<string, boolean> {
  return tourStatuses.reduce((toursMap, current) => {
    toursMap.set(current.tour.uuid, current.status === TourStatusType.Pending)
    return toursMap
  }, new Map<string, boolean>())
}

export default productTour.reducer
