import en from '@lang/en.json'
import es from '@lang/es.json'

export interface i18Config {
  locale: string
  availableLocales: string[]
  messages: Record<string, any>
}

const messagesMap: Record<string, any> = {
  en,
  es
}

class LocaleService {
  private readonly locale: string
  private readonly availableLocales: string[]
  private messages: Record<string, any>

  constructor(config: i18Config) {
    this.locale = config.locale
    this.availableLocales = config.availableLocales
    this.messages = messagesMap[this.locale] ?? config.messages
  }

  getAvailableLocales() {
    return this.availableLocales
  }

  getDefaultLocale() {
    return this.locale
  }

  getDefaultMessages() {
    return this.messages
  }

  async getMessages(lang: string) {
    if (this.availableLocales.includes(lang)) {
      return messagesMap[lang]
    }
    return messagesMap[this.locale]
  }

  saveMessages(messages: Record<string, any>) {
    this.messages = messages
  }
}

export default new LocaleService({
  locale: 'en',
  availableLocales: ['en', 'es'],
  messages: en
})
