import { API_URL, CHANGE_PASSWORD_ENDPOINT } from '@constants/index'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { ChangePasswordDto, PwdTypes } from '@models/dtos/ChangePassword'
import { ResponseBase } from '@shared/ResponseBase'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function changePwdService(
  token: string,
  data: ChangePasswordDto
): Promise<PwdTypes | undefined> {
  const resp = await SecureRequestMiddleware<
    ChangePasswordDto,
    AxiosResponse<ResponseBase<PwdTypes>>
  >(async (config: AxiosRequestConfig, _data: ChangePasswordDto) => {
    return await axios.post(
      String(API_URL) + String(CHANGE_PASSWORD_ENDPOINT),
      _data,
      config
    )
  }, data)

  if (resp.status === 200) {
    return resp.data.result
  }

  return undefined
}
