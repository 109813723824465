import { ServiceArea } from '@models/ServiceArea'
import { Session } from '@models/Session'
import { WeekDay, WeekDaysFilter } from '@models/WeekDaysFilter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { weekDaysFilterRequestService } from '@services/weekDaysFilter.service'
import { GetStorage } from '@shared/StorageHandler'
import { CustomPayload } from '@store/CustomPayload'

export interface WeekDaysFilterState {
  weekDaysFilter: WeekDaysFilter
  isLoading: boolean
  error: unknown | undefined
}

const defaultWeekDaysFilter: WeekDaysFilter = {
  daysToWork: [],
  serviceAreaIds: []
}

const initialState: WeekDaysFilterState = {
  weekDaysFilter: defaultWeekDaysFilter,
  isLoading: false,
  error: undefined
}

export const weekDaysFilterRequest = createAsyncThunk(
  'weekDaysFilter/weekDaysFilterRequest',
  async (data: WeekDaysFilter | undefined, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return weekDaysFilterRequestService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const weekDaysFilterSlice = createSlice({
  name: 'weekDaysFilter',
  initialState,
  reducers: {
    updateDaysToWork: (
      state: WeekDaysFilterState,
      action: CustomPayload<WeekDay[]>
    ) => {
      const weekDaysFilter = state.weekDaysFilter
      weekDaysFilter.daysToWork = action.payload

      state.weekDaysFilter = weekDaysFilter
    },
    updateServiceAreaIds: (
      state: WeekDaysFilterState,
      action: CustomPayload<string[]>
    ) => {
      state.weekDaysFilter.serviceAreaIds = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(weekDaysFilterRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(weekDaysFilterRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.weekDaysFilter = payload
      })
      .addCase(weekDaysFilterRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default weekDaysFilterSlice.reducer
