import { ProductTour } from '@models/ProductTour'
import { StepType } from '@reactour/tour'

const steps: StepType[] = [
  {
    selector: '.product-tour-capture-control',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-0',
    stepInteraction: false
  },
  {
    selector: '.product-tour-capture-control .filters-safe-mode',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-1'
  },
  {
    selector: '.product-tour-capture-control .filters-min-amount ',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-2'
  },
  {
    selector: '.product-tour-capture-control .filters-arrival-time ',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-3'
  },
  {
    selector: '.product-tour-capture-control .capture-control',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-4'
  },
  {
    selector:
      '.product-tour-list-block .list-blocks-content.active-mask .nextui-table-row ',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-5',
    stepInteraction: false
  },
  {
    selector:
      '.product-tour-list-block .list-blocks-content.active-mask .action-icon > .icon',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-6'
  },
  {
    selector: '.product-tour-capture-control .capture-control',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-7'
  },
  {
    selector: '.capture-tour',
    content: 'tour.8bb0ba8e-2bb0-41a0-a673-fc214cef1380.step-8',
    stepInteraction: false
  }
]

const CaptureProductTour: ProductTour = {
  tourId: '8bb0ba8e-2bb0-41a0-a673-fc214cef1380',
  steps
}

export default CaptureProductTour
