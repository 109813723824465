import BButton from '@components/bButton/BButton'
import BInput from '@components/BInput'
import { blocksIcons } from '@constants/icons'
import { ForgotPasswordDto } from '@models/dtos/ForgotPasswordDto'
import { Button } from '@nextui-org/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function StepOne({
  step,
  isLoading,
  action
}: {
  step: number
  isLoading: boolean
  action: (value: ForgotPasswordDto) => void
}) {
  const { formatMessage: f } = useIntl()
  const idStep = 1
  const isActive = idStep === step

  const initializeValue: ForgotPasswordDto = {
    email: ''
  }

  const forgotPasswordFormSchema = yup.object({
    email: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Email is required'
        })
      )
  })

  return (
    <>
      {isActive && (
        <div className="flex flex-col gap-6 w-auto pt-52">
          <h4>{f({ id: 'Forgot password ?' })} </h4>
          <p>{f({ id: 'page.forgotPassword.description' })}</p>

          <Formik
            initialValues={initializeValue}
            validationSchema={forgotPasswordFormSchema}
            onSubmit={action}
          >
            {(formik) => (
              <Form className="w-full">
                <div className="flex flex-col justify-center gap-6">
                  <BInput
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    error={formik.errors.email}
                    contentLeft={blocksIcons.mail}
                    type="email"
                    size="lg"
                    clearable
                  />
                  <div className="flex justify-center mt-4">
                    <BButton
                      isLoading={isLoading}
                      text={f({
                        id: 'Reset password'
                      })}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}
