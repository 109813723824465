import { ClassNames } from 'shared'

export default function BIcon({
  className,
  icon,
  size,
  color
}: {
  className?: string
  icon: string
  size?: number
  color?: string
}) {
  return (
    <span
      className={ClassNames('icons', className)}
      style={{ fontSize: size, lineHeight: 1, color }}
    >
      {icon}
    </span>
  )
}
