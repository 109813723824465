import {
  API_URL,
  AUTH_ENDPOINT,
  AUTH_PROVIDER_ENDPOINT,
  AUTH_REFRESH_TOKEN_ENDPOINT
} from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Session } from '@models/Session'
import { ILoginData } from '@store/auth/auth.slice'
import { IAuthProviderData } from '@store/auth/authProvider.slice'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export interface IAuthToken {
  accessToken: string
  refreshToken: string
}

export const loginRequestService = async (loginData: ILoginData) => {
  const resp = await axios.post<ILoginData, AxiosResponse<Session, unknown>>(
    String(API_URL) + String(AUTH_ENDPOINT),
    loginData
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export const authProviderRequestService = async (
  token: string,
  authData: IAuthProviderData
) => {
  const resp = await SecureRequestMiddleware<IAuthProviderData>(
    async (config: AxiosRequestConfig, _data: IAuthProviderData) => {
      return await axios.post<IAuthProviderData>(
        String(API_URL) + String(AUTH_PROVIDER_ENDPOINT),
        _data,
        config
      )
    },
    authData
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}

export const RefreshTokenService = async (
  session: Session
): Promise<AxiosResponse<Session, unknown>> => {
  const data: IAuthToken = {
    accessToken: session.token,
    refreshToken: session.refreshToken
  }
  return await axios.post(
    String(API_URL) + String(AUTH_REFRESH_TOKEN_ENDPOINT),
    data
  )
}
