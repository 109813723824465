import BButton from '@components/bButton/BButton'
import React from 'react'
import { useIntl } from 'react-intl'

export default function StepFinal({
  isLoading,
  action
}: {
  isLoading: boolean
  action?: () => void
}) {
  const { formatMessage: f } = useIntl()

  return (
    <div className="flex flex-col gap-6 w-auto pt-52">
      <h4>{f({ id: 'New password confirmed successful' })} </h4>
      <p>{f({ id: 'page.forgotPassword.stepFinal.description' })}</p>

      <div className="flex justify-center mt-4">
        <BButton onClick={action} text={f({ id: 'Okay' })}></BButton>
      </div>
    </div>
  )
}
