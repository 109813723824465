import { Alert, AlertStatus } from '@models/dtos/Alert'
import { createSlice } from '@reduxjs/toolkit'
import { CustomPayload } from '@store/CustomPayload'

interface NotificationsState {
  alerts: Alert[]
}

const initialState: NotificationsState = {
  alerts: new Array<Alert>()
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add(state: NotificationsState, action: CustomPayload<Alert>) {
      if (action.payload?.metadata !== undefined) {
        const found = state.alerts.findIndex(
          (x) =>
            x.status === AlertStatus.Delivered &&
            x.title === action.payload.title &&
            x.metadata === action.payload.metadata
        )

        if (found > -1) {
          return
        }
      }

      state.alerts.push(action.payload)
    },
    remove(state: NotificationsState, action: CustomPayload<Alert>) {
      state.alerts = state.alerts.filter(
        (notification) => notification !== action.payload
      )
    }
  }
})

export default notificationsSlice.reducer
