import { useStorage } from '@hooks/useStorage'
import i18nService from '@services/i18n.service'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { IntlProvider } from 'react-intl'

const defaultLocale = i18nService.getDefaultLocale()
const defaultMessage = i18nService.getDefaultMessages()

export const CustomIntlContext = createContext(null)

export function CustomIntlProvider({ children }: { children: ReactNode }) {
  const [storedValue, setStoredValue] = useStorage<string>('lang')
  const [locale, setLocale] = useState(defaultLocale)
  const [messages, setMessages] = useState(defaultMessage)

  const updateLocale = useCallback(
    (newLocale: string) => {
      setLocale(newLocale)

      // Save the new locale into the sessionStorage
      if (typeof window !== 'undefined') {
        setStoredValue(newLocale)
      }

      i18nService.getMessages(newLocale).then((data) => {
        setMessages(data)
      })
    },
    [setStoredValue]
  )

  const getDefaultLocale = useCallback(() => {
    if (storedValue !== '') return storedValue

    // Device preferences
    if (/^en\b/.test(navigator.language)) {
      return 'en'
    }

    if (/^es\b/.test(navigator.language)) {
      return 'es'
    }

    return locale
  }, [locale, storedValue])

  useEffect(() => {
    if (messages === undefined) {
      const newLocale = getDefaultLocale()
      if (locale !== newLocale) setLocale(newLocale)

      i18nService.getMessages(newLocale).then((data) => {
        setMessages(data)
      })
    }
  }, [locale, updateLocale, messages, getDefaultLocale])

  useEffect(() => {
    const newLocale = storedValue !== '' ? storedValue : locale

    if (locale !== newLocale) setLocale(newLocale)

    i18nService.getMessages(newLocale).then((data) => {
      setMessages(data)
    })
  }, [locale, storedValue])

  return (
    <CustomIntlContext.Provider value={[locale, updateLocale]}>
      <IntlProvider
        key={locale}
        locale={locale}
        defaultLocale={locale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </CustomIntlContext.Provider>
  )
}

export const useLocale = () => useContext(CustomIntlContext)
