import { Icons } from '@constants/index'
import { createSlice } from '@reduxjs/toolkit'
import { CustomPayload } from '@store/CustomPayload'

export interface NavigationState {
  routes: NavRoute[]
  activeRouteId: number
}

export interface NavRoute {
  name: string
  icon: string
  active: boolean
}

const initialState: NavigationState = {
  routes: [
    {
      // FIXME: add translation
      name: 'Dashboard',
      icon: Icons.blocksIcons.house,
      active: true
    },
    // {
    //   name: 'Schedule',
    //   icon: Icons.blocksIcons.calendarFill,
    //   active: false
    // },
    {
      name: 'Referred',
      icon: Icons.blocksIcons.referred,
      active: false
    },
    {
      name: 'Settings',
      icon: Icons.blocksIcons.settings,
      active: false
    }
  ],
  activeRouteId: 0
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    changeActiveRoute(state: NavigationState, action: CustomPayload<number>) {
      state.activeRouteId = action.payload
    }
  }
})

export default navigationSlice.reducer
