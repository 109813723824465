import { API_URL, RESEND_OTP_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import axios, { AxiosRequestConfig } from 'axios'

export async function resendOtpService() {
  const resp = await SecureRequestMiddleware(
    async (config: AxiosRequestConfig) => {
      return await axios.get(
        String(API_URL) + String(RESEND_OTP_ENDPOINT),
        config
      )
    }
  )

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
