import BButton from '@components/bButton/BButton'
import BInput from '@components/BInput'
import { blocksIcons } from '@constants/icons'
import { NewPasswordDto } from '@models/dtos/NewPasswordDto'
import { Form, Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function SetNewPassword({
  action,
  isLoading
}: {
  action: () => void
  isLoading: boolean
}) {
  const { formatMessage: f } = useIntl()

  const initializeValue: NewPasswordDto = {
    password: '',
    passwordConfirm: ''
  }

  const newPasswordFormSchema = yup.object({
    password: yup
      .string()
      .min(8, ({ min }) =>
        f(
          {
            id: 'Password must be at least {min} characters'
          },
          { min }
        )
      )
      .required(
        f({
          id: 'Password is required'
        })
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], f({ id: 'Passwords must match' }))
      .required(
        f({
          id: 'Confirm password is required'
        })
      )
  })

  return (
    <div className="new-password flex flex-col gap-6 w-auto pt-52">
      <h4>{f({ id: 'Please enter a new password below' })} </h4>
      <Formik
        initialValues={initializeValue}
        validationSchema={newPasswordFormSchema}
        onSubmit={action}
      >
        {(formik) => (
          <Form className="w-full">
            <div className="flex flex-col justify-center gap-6">
              <BInput
                label={f({ id: 'New password' })}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                error={formik.errors.password}
                contentLeft={blocksIcons.unlock}
                type="password"
                size="lg"
                clearable
              />
              <BInput
                label={f({ id: 'Confirm new password' })}
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange('passwordConfirm')}
                error={formik.errors.passwordConfirm}
                contentLeft={blocksIcons.unlock}
                type="password"
                size="lg"
                clearable
              />

              <div className="flex justify-center mt-4">
                <BButton
                  isLoading={isLoading}
                  text={f({
                    id: 'Save'
                  })}
                  type="submit"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
