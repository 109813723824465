import { ChangePasswordDto, PwdTypes } from '@models/dtos/ChangePassword'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changePwdService } from '@services/changePwd.service'
import { GetStorage } from '@shared/index'

interface ChangePwdState {
  lastType: PwdTypes | undefined
  isLoading: boolean
  error: unknown | undefined
}

const initialState: ChangePwdState = {
  lastType: undefined,
  isLoading: false,
  error: undefined
}

export const changePwdRequest = createAsyncThunk(
  'changePwd/changePwdRequest',
  async (data: ChangePasswordDto, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      const resp = changePwdService(session.token, data)

      return resp
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const changePwdSlice = createSlice({
  name: 'changePwd',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePwdRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changePwdRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.lastType = payload
      })
      .addCase(changePwdRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default changePwdSlice.reducer
