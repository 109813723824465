import { Capture, CaptureStatusType } from '@models/Capture'
import { StatusReason } from '@models/dtos/StatusReason'
import { Offer } from '@models/Offer'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetStorage } from '@shared/StorageHandler'
import moment from 'moment'

interface CaptureState {
  capture: Capture | undefined
  offers: Offer[] | undefined
  isLoading: boolean
  isCapturing: boolean
  error: any | undefined
}

export const defaultCapture: Capture = {
  status: CaptureStatusType.Stopped,
  safeMode: true,
  blockMinimumLength: 30,
  arrivalTime: moment().add(45, 'minutes').unix(),
  serviceAreas: [],
  startTime: '06:00',
  endTime: '23:59',
  regionId: 0,
  durationTime: 120
}

const initialState: CaptureState = {
  capture: defaultCapture,
  offers: undefined,
  isLoading: false,
  isCapturing: false,
  error: undefined
}

export interface CapturePayload<T> {
  payload: T
  type: string
}

export const captureRequest = createAsyncThunk(
  'captureTour/captureRequest',
  async (data: Capture, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      /* return captureRequestService(session.token, data) */
      return null
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const offersRequest = createAsyncThunk(
  'captureTour/offersRequest',
  async (data: StatusReason, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      /* return offersRequestService(session.token, data) */
      return null
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const declineOffer = createAsyncThunk(
  'captureTour/declineOffer',
  async (data: StatusReason, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      /* return offersRequestService(session.token, data) */
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const captureTourSlice = createSlice({
  name: 'captureTour',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(captureRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(captureRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isCapturing = !state.isCapturing
        state.capture = null
      })
      .addCase(captureRequest.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = error
      })

      // Blocks
      .addCase(offersRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(offersRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.offers = null
      })
      .addCase(offersRequest.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = error
      })
  }
})

export default captureTourSlice.reducer
