import { ForgotPasswordDto } from '@models/dtos/ForgotPasswordDto'
import {
  forgotPasswordRequest,
  ValidateResetTokenRequest,
  validateResetTokenRequest
} from '@store/forgotPassword/forgotPassword.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

interface UseForgotPasswordReturnType {
  activeStep: number
  resetToken: string
  isLoading: boolean
  isSuccess: boolean
  isReseted: boolean
  isValidToken: boolean | undefined
  nextStep: () => void
  resetPassword: (value: ForgotPasswordDto) => void
  saveNewPassword: () => void
}
export function useForgotPassword(
  maxSteps: number
): UseForgotPasswordReturnType {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()
  const { isValidToken, isLoading, isSuccess } = useAppSelector(
    (state) => state.forgotPassword
  )

  const [activeStep, setActiveStep] = useState(1)
  const [resetToken, setResetToken] = useState<string>()
  const [isReseted, setIsReseted] = useState(false)
  const nextStep = () => {
    if (activeStep >= maxSteps) return
    setActiveStep(activeStep + 1)
  }

  const resetPassword = (value: ForgotPasswordDto) => {
    dispatch(forgotPasswordRequest(value))
  }

  const saveNewPassword = () => {
    setIsReseted(true)
    console.log('save new password')
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const _resetToken = urlParams.get('resetToken') ?? undefined

    if (_resetToken !== undefined) {
      setResetToken(_resetToken)
      const dataRequest: ValidateResetTokenRequest = {
        resetToken: _resetToken
      }

      dispatch(validateResetTokenRequest(dataRequest))
      console.log('reset password', _resetToken)
    }
  }, [])

  const errorMsg = () => {
    return f({
      id: 'The password reset verification link are invalid, please repeat previous steps.'
    })
  }

  useEffect(() => {
    if (isValidToken !== undefined && isValidToken === false) {
      throw new Error(errorMsg())
    }
  }, [isValidToken])

  useEffect(() => {
    if (isSuccess === false) {
      throw new Error(f({ id: 'page.forgotPassword.error.sendEmail' }))
    }
  }, [isSuccess])

  return {
    activeStep,
    resetToken,
    isLoading,
    isSuccess,
    isReseted,
    isValidToken,
    nextStep,
    resetPassword,
    saveNewPassword
  }
}
