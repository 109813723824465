// BlocksIcons-Regular

export type BlocksIcons =
  | 'currentLocation'
  | 'location'
  | 'money'
  | 'pointLocation'
  | 'referred'
  | 'mail'
  | 'collapse'
  | 'expand'
  | 'signal'
  | 'warning'
  | 'arrowDown'
  | 'arrowIOsDownward'
  | 'calendar'
  | 'clock'
  | 'cube'
  | 'person'
  | 'checkmark'
  | 'unlock'
  | 'lockOutline'
  | 'home'
  | 'language'
  | 'phone'
  | 'lock'
  | 'at'
  | 'calendarSpace'
  | 'house'
  | 'settings'
  | 'station'
  | 'wallet'
  | 'factureMoney'
  | 'statistics'
  | 'calendarFill'
  | 'personFill'
  | 'lockFill'
  | 'world'
  | 'arrowIOsForward'
  | 'trash'
  | 'bell'
  | 'fishing'
  | 'trendingUp'
  | 'gift'

export const blocksIcons: Record<BlocksIcons, string> = {
  currentLocation: '\ue000',
  location: '\ue001',
  money: '\ue002',
  pointLocation: '\ue003',
  referred: '\ue019',
  mail: '\ue01b',
  collapse: '\ue01c',
  expand: '\ue01d',
  signal: '\ue01e',
  warning: '\ue01f',
  arrowDown: '\ue020',
  arrowIOsDownward: '\ue021',
  calendar: '\ue023',
  clock: '\ue024',
  cube: '\ue028',
  person: '\ue029',
  checkmark: '\ue02a',
  unlock: '\ue02b',
  lockOutline: '\ue02c',
  home: '\ue02d',
  language: '\ue004',
  phone: '\ue005',
  lock: '\ue006',
  at: '\ue007',
  calendarSpace: '\ue008',
  house: '\ue009',
  settings: '\ue030',
  station: '\ue031',
  wallet: '\ue033',
  factureMoney: '\ue034',
  statistics: '\ue035',
  calendarFill: '\ue036',
  personFill: '\ue037',
  lockFill: '\ue038',
  world: '\ue039',
  arrowIOsForward: '\ue040',
  trash: '\ue041',
  bell: '\ue042',
  fishing: '\ue043',
  trendingUp: '\ue044',
  gift: '\ue045'
}
