import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loginRequestService } from '@services/auth.service'
import { Session } from 'models/Session'
import { GetStorage, SetStorage } from 'shared/StorageHandler'

interface IAuthState {
  session: Session
  isLoading: boolean
  isAuthenticated: boolean
  error: any
}

export interface ILoginData {
  email: string
  password: string
}

export const loginRequest = createAsyncThunk(
  'auth/loginRequest',
  async (loginData: ILoginData, { rejectWithValue }) => {
    try {
      const resp = await loginRequestService(loginData)
      if (resp?.token !== undefined) {
        SetStorage<Session>('session', resp)
      }
      return resp
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

function initState(): IAuthState {
  const session = GetStorage<Session>('session') as Session
  return {
    session,
    isLoading: false,
    isAuthenticated: session?.token !== undefined,
    error: undefined
  }
}

function logoutAction(state: IAuthState) {
  state.isAuthenticated = false
  state.session = undefined
  SetStorage<Session>('session', undefined)
}

function refreshTokenAction(state: IAuthState) {
  state.isAuthenticated = true
  state.session = GetStorage<Session>('session') as Session
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initState(),
  reducers: {
    logout: logoutAction,
    refreshToken: refreshTokenAction
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isAuthenticated = true
        state.session = payload
      })
      .addCase(loginRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isAuthenticated = false
        state.error = payload
      })
  }
})

export const { logout } = authSlice.actions
export default authSlice.reducer
