import { Text } from '@nextui-org/react'
import { ClassNames } from '@shared/DataValidation'

export default function BrandLogo({ small = false }: { small?: boolean }) {
  const BRAND_NAME = 'UNLOCKFLEX'
  const BRAND_SLOGAN = 'innovation to flex world'

  const brandNameSmall = small
    ? '!leading-none text-2xl: md:text-xl'
    : 'text-[30px]'
  const sloganSmall = small
    ? '!leading-none text-[8px] md:text-[10px]'
    : 'text-xs'

  return (
    <div className="brand-logo-container">
      <Text
        className={ClassNames('brand-name anim-gradient', brandNameSmall)}
        css={{
          fontFamily: 'Montserrat-Alt1',
          letterSpacing: '0em',
          lineHeight: '1em'
        }}
      >
        {BRAND_NAME.toUpperCase()}
      </Text>
      <Text
        className={ClassNames('brand-slogan text-center', sloganSmall)}
        color="#9240A5"
        css={{
          fontFamily: 'Karla-Regular',
          letterSpacing: '0em',
          lineHeight: '1em'
        }}
      >
        {BRAND_SLOGAN.toLowerCase()}
      </Text>
    </div>
  )
}
