import { Capture, CaptureStatusType } from '@models/Capture'
import {
  CaptureSignalStatusMessage,
  CaptureSignalStatusType
} from '@models/CaptureSignalStatusMessage'
import { RejectOffersRequest } from '@models/dtos/RejectOffersRequest'
import { StatusReason } from '@models/dtos/StatusReason'
import { Offer } from '@models/Offer'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { captureRequestService } from '@services/capture.service'
import { offersRequestService } from '@services/offers.service'
import { rejectOffersRequestService } from '@services/reject_offers.service'
import { GetStorage } from '@shared/StorageHandler'
import { CustomPayload } from '@store/CustomPayload'
import moment from 'moment'

interface CaptureState {
  capture: Capture | undefined
  offers: Offer[] | undefined
  isLoading: boolean
  isCapturing: boolean
  error: any | undefined
}

export const defaultCapture: Capture = {
  status: CaptureStatusType.Stopped,
  safeMode: true,
  blockMinimumLength: 30,
  arrivalTime: moment().add(45, 'minutes').unix(),
  serviceAreas: [],
  regionId: 0,
  startTime: '06:00',
  endTime: '23:59',
  durationTime: 60 * 60 * 10 // TODO: 10 hours, use this from configuration
}

const initialState: CaptureState = {
  capture: defaultCapture,
  offers: undefined,
  isLoading: false,
  isCapturing: false,
  error: undefined
}

export interface CapturePayload<T> {
  payload: T
  type: string
}

export const captureRequest = createAsyncThunk(
  'capture/captureRequest',
  async (data: Capture, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session

      return captureRequestService(session.id, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const offersRequest = createAsyncThunk(
  'capture/offersRequest',
  async (data: StatusReason, { rejectWithValue }) => {
    try {
      return offersRequestService()
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const rejectOffers = createAsyncThunk(
  'capture/rejectOffers',
  async (data: RejectOffersRequest, { rejectWithValue }) => {
    try {
      return rejectOffersRequestService(data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const captureSlice = createSlice({
  name: 'capture',
  initialState,
  reducers: {
    updateCapture: (state: CaptureState, action: CapturePayload<Capture>) => {
      state.capture = action.payload
    },
    updateRegionId: (state: CaptureState, action: CapturePayload<number>) => {
      state.capture.regionId = action.payload
    },
    updateCaptureStatus: (
      state: CaptureState,
      action: CustomPayload<CaptureSignalStatusMessage>
    ) => {
      switch (action.payload.signal) {
        case CaptureSignalStatusType.Stopped:
          state.isCapturing = false
          state.capture.status = CaptureStatusType.Stopped
          break
        case CaptureSignalStatusType.Started:
          state.isCapturing = true
          state.capture.status = CaptureStatusType.Started
          break
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(captureRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(captureRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isCapturing = !state.isCapturing
        state.capture = payload
      })
      .addCase(captureRequest.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = error
      })

      // Blocks
      .addCase(offersRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(offersRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.offers = payload
      })
      .addCase(offersRequest.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = error
      })

      // Reject offers
      .addCase(rejectOffers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(rejectOffers.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.offers = deleteOffers(payload, state.offers)
      })
      .addCase(rejectOffers.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = error
      })
  }
})

function deleteOffers(toRemove: string[], offers?: Offer[]) {
  if (offers === undefined) return

  return offers.filter((offer) => !toRemove.includes(offer.offerId))
}

export default captureSlice.reducer
