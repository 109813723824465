import { ClientRegister } from '@models/ClientRegister'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { registerRequestService } from '@services/register.service'

interface RegisterState {
  isFinished: boolean
  isLoading: boolean
  error: any | undefined
}

const initialState: RegisterState = {
  isFinished: false,
  isLoading: false,
  error: undefined
}

export const registerRequest = createAsyncThunk(
  'register/registerRequest',
  async (data: ClientRegister, { rejectWithValue }) => {
    try {
      const resp = await registerRequestService(data)
      return resp
    } catch (error) {
      return rejectWithValue(error?.message)
    }
  }
)

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerRequest.fulfilled, (state) => {
        state.isLoading = false
        state.isFinished = true
      })
      .addCase(registerRequest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  }
})

export default registerSlice.reducer
