import { Button, Loading } from '@nextui-org/react'
import { ClassNames } from 'shared'

type buttonType = 'button' | 'reset' | 'submit'

export default function BButton({
  disabled,
  isLoading = false,
  text,
  className,
  onClick,
  bordered = false,
  type = 'button',
  auto = false,
  size = 'md',
  light = false
}: {
  disabled?: boolean
  isLoading?: boolean
  text: string
  className?: string
  onClick?: () => void
  bordered?: boolean
  type?: buttonType
  auto?: boolean
  size?: 'sm' | 'md' | 'lg'
  light?: boolean
}) {
  return (
    <div className="bbutton relative text-center">
      <Button
        disabled={(isLoading ?? false) || (disabled ?? false)}
        className={ClassNames(className)}
        bordered={bordered}
        onPress={onClick}
        type={type}
        auto={auto}
        size={size}
        light={light}
      >
        <span className="relative">
          <span className={isLoading ? 'opacity-0' : 'opacity-100'}>
            {text}
          </span>
          {isLoading && (
            <span className="loading-spinner">
              <Loading color="currentColor" size="sm" />
            </span>
          )}
        </span>
      </Button>
    </div>
  )
}
