import BButton from '@components/bButton/BButton'
import BIcon from '@components/bIcon'
import { colors } from '@constants/colors'
import { blocksIcons } from '@constants/icons'
import { useIntl } from 'react-intl'

export default function FinishStep() {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <div className="flex flex-col justify-center content-center w-full h-full pt-16 md:pt-0">
        <h2 className="text-center text-2xl mb-8 px-4">
          {f({
            id: 'You has been registered successfully'
          })}
        </h2>

        <p className="text-center mb-8">
          <BIcon
            icon={blocksIcons.checkmark}
            color={colors.green[300]}
            size={64}
          />
        </p>

        <div className="w-full flex justify-center mt-4">
          <BButton
            onClick={() => {
              window.location.href = '/'
            }}
            text={f({
              id: 'Sign in'
            })}
          />
        </div>
      </div>
    </>
  )
}
