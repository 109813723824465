import { API_URL, CAPTURE_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { Capture } from '@models/Capture'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// TODO bring this data from BlockAPI
export const captureRequestService = async (
  userId: number,
  data?: Capture,
  prevValue?: Capture
) => {
  // TODO: update filters first

  let resp: AxiosResponse<Capture, unknown> | undefined

  // POST method
  if (data !== undefined) {
    resp = await SecureRequestMiddleware<Capture>(
      async (config: AxiosRequestConfig, _data: Capture) => {
        return await axios.post<Capture>(
          String(API_URL) +
            String(CAPTURE_ENDPOINT).replace('{userId}', userId.toString()),
          _data,
          config
        )
      },
      data
    )

    if (resp.status !== 200) return undefined

    // return DiffUpdate(data, prevValue)
    return data
  }

  // GET method
  resp = await SecureRequestMiddleware<Capture>(
    async (config: AxiosRequestConfig) => {
      return await axios.get<Capture>(
        String(API_URL) +
          String(CAPTURE_ENDPOINT).replace('{userId}', userId.toString()),
        config
      )
    }
  )

  if (resp.status !== 200) return undefined

  return resp.data
}
