import { Link } from '@nextui-org/react'
import React from 'react'
import { useIntl } from 'react-intl'

export default function CheckEmail() {
  const { formatMessage: f } = useIntl()

  return (
    <div className="flex flex-col gap-6 w-auto pt-52">
      <h4>{f({ id: 'Check your email' })} </h4>
      <p>{f({ id: 'page.forgotPassword.checkEmail.description' })}</p>

      <div className="absolute bottom-4 left-0 w-full">
        <p className="text-xs flex justify-center">
          {f({ id: 'page.forgotPassword.checkEmail.footer' })}
          <Link
            href="/forgotPassword"
            className="mb-1 px-1 text-xs font-bold text-right text-blue-50"
          >
            {f({ id: 'try again' })}
          </Link>
        </p>
      </div>
    </div>
  )
}
