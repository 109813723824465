import { API_URL, REGISTER_ENDPOINT } from '@constants'
import { ClientRegister } from '@models/ClientRegister'
import axios from 'axios'

export async function registerRequestService(data: ClientRegister) {
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const resp = await axios.post(
    String(API_URL) + String(REGISTER_ENDPOINT),
    data,
    axiosOptions
  )
  if (resp.status !== 200) {
    throw new Error(
      `Couldn't register user, status:${resp.status}, message: ${resp.statusText}`
    )
  }

  return resp
}
