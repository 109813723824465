import BInput from '@components/BInput'
import { blocksIcons } from '@constants/icons'
import {
  AccountVerificationStatus,
  AccountVerificationStatusType,
  StepParamsType
} from '@hooks/useWizardRegisterForm'
import { ClientRegister } from '@models/ClientRegister'
import { Progress, Text } from '@nextui-org/react'
import { FormikProps } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useIntl } from 'react-intl'

export default function StepTwo({
  formik,
  step,
  isLoading,
  isAmazonAccountValid
}: StepParamsType) {
  const { formatMessage: f } = useIntl()

  const idStep = 1
  // if (!isActive) return

  const showInvalidAccountError =
    isAmazonAccountValid === AccountVerificationStatus.INVALID

  return (
    <>
      {idStep === step && (
        <>
          <VerificationInformation
            isLoading={isLoading}
            isAmazonAccountValid={isAmazonAccountValid}
            f={f}
          />
          <InvalidAccountError isVisible={showInvalidAccountError} f={f} />
          <BodyContainer isVisible={isLoading} formik={formik} f={f} />
        </>
      )}
    </>
  )
}

function BodyContainer({
  isVisible,
  formik,
  f
}: {
  isVisible: boolean
  formik: FormikProps<ClientRegister>
  f: any
}) {
  if (isVisible) return
  return (
    <>
      <BInput
        label={f({
          id: 'Amazon Phone number'
        })}
        value={formik.values.amzPhone}
        onChange={formik.handleChange('amzPhone')}
        error={formik.errors.amzPhone}
        contentLeft={blocksIcons.phone}
        size="lg"
      />
      <BInput
        label={f({
          id: 'Amazon Email'
        })}
        value={formik.values.amzEmail}
        onChange={formik.handleChange('amzEmail')}
        error={formik.errors.amzEmail}
        contentLeft={blocksIcons.mail}
        size="lg"
      />
      <BInput
        label={f({
          id: 'Amazon Password'
        })}
        value={formik.values.amzPassword}
        onChange={formik.handleChange('amzPassword')}
        error={formik.errors.amzPassword}
        contentLeft={blocksIcons.unlock}
        size="lg"
        type="password"
      />
    </>
  )
}

function LoadingView({ isVisible, f }: { isVisible: boolean; f: any }) {
  if (!isVisible) return
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-[360px] flex flex-col items-center justify-center p-2"
    >
      {/* <Loading size="lg" /> */}
      <Progress indeterminated size="xs" value={50} />
      <Text h5 className="mt-8">
        {f({ id: 'Verifying your Amazon account' })}
      </Text>
    </motion.div>
  )
}

function VerificationInformation({
  isLoading,
  isAmazonAccountValid,
  f
}: {
  isLoading: boolean
  isAmazonAccountValid: AccountVerificationStatusType
  f: any
}) {
  return (
    <AnimatePresence>
      <LoadingView isVisible={isLoading} f={f} />
    </AnimatePresence>
  )
}

function InvalidAccountError({ isVisible, f }: { isVisible: boolean; f: any }) {
  if (!isVisible) return
  return (
    <Text className="text-red-100">
      {f({
        id: 'register.amz.account.verification.error'
      })}
    </Text>
  )
}
